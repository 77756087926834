import { useTranslation } from 'react-i18next'
import { useDisableUsersQuery } from '../query'
import OverviewUsersTable from '../Component/OverviewUsersTable'

const DisableSuperUsers = () => {
  const { t } = useTranslation()

  return (
    <OverviewUsersTable
      query={useDisableUsersQuery}
      title={t('adminMenu.administrator.admins.disable')}
    />
  )
}

export default DisableSuperUsers
