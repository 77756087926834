import { useTranslation } from 'react-i18next'
import OverviewUsersTable from './Component/OverviewUsersTable'
import { useSuperUsersQuery } from './query'

const OverviewUsers = () => {
  const { t } = useTranslation()

  return (
    <OverviewUsersTable
      query={useSuperUsersQuery}
      title={t('adminMenu.administrator.admins.admins')}
    />
  )
}

export default OverviewUsers
