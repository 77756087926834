import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import * as yup from 'yup'
import CompanySchoolFormSelector from '~/components/CompanySchoolFormSelector'
import FullPageLoading from '~/components/FullPageLoading'
import { ADMIN_INVITE_GOOGLE_DOMAIN, ADMIN_OVERVIEW } from '~/constants/Routes'
import useLanguage from '~/hooks/useLanguage'
import { parseApiErrorMessage } from '~/utils/helpers'
import { useGetOrgsQuey } from '../InviteAzureTenants/query'
import { useAzureRegistrationDetailQuery } from '../Registrations/query'
import { useInviteGoogleDomainMutation } from './mutation'

const CheckboxComponent = ({ field: props }) => (
  <Checkbox {...props} checked={props.value} onChange={e => props.onChange(e.target.checked)} />
)

CheckboxComponent.propTypes = {
  field: PropTypes.shape({
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
  }).isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
}

const initialValue = {
  hosted_domain: '',
  school_id: '',
  name: '',
  google_domain_admin_email: '',
  apply_to_child: false,
  language: 'en',
  entity_id: ''
}

const InviteGoogleDomain = () => {
  const localization = 'pages.adminOverview.inviteGoogleDomain'
  const [searchParams] = useSearchParams()
  const regId = searchParams.get('registration-id')
  const { data: userRegistration, isLoading: isLoadingReg } = useAzureRegistrationDetailQuery(regId)
  const { t } = useTranslation()
  const { languages, language, setLanguage } = useLanguage()

  const schema = {
    hosted_domain: yup.string().required(t('validation.domainRequired')),
    name: yup.string().required(t('validation.nameRequired')),
    apply_to_child: yup.boolean().nullable(),
    school_id: yup
      .string()
      .nullable()
      .test({
        name: 'schoolEntityCheck',
        message: t('validation.schoolAndEntityReuiredTogether'),
        test: function (value) {
          const { entity_id } = this.parent
          if ((value && !entity_id) || (!value && entity_id)) {
            return false
          }
          return true
        }
      }),
    entity_id: yup
      .string()
      .nullable()
      .test({
        name: 'entitySchoolCheck',
        message: t('validation.schoolAndEntityReuiredTogether'),
        test: function (value) {
          const { school_id } = this.parent
          if ((value && !school_id) || (!value && school_id)) {
            return false
          }
          return true
        }
      })
  }

  const invitationScheme = yup.object({
    ...schema,
    google_domain_admin_email: yup
      .string()
      .email(t('validation.emailInvalid'))
      .required(t('validation.ggEmailRequired'))
  })

  const setupSchema = yup.object(schema)

  const { data: orgData } = useGetOrgsQuey()
  const { mutate, isLoading } = useInviteGoogleDomainMutation()
  const navigate = useNavigate()

  const defaultValues = useMemo(() => {
    if (regId !== null && userRegistration && userRegistration?.status === 'pending') {
      const tenantDomain = userRegistration.tenant_domain
      const userLang = userRegistration.language ?? 'en'

      return {
        hosted_domain: tenantDomain ?? '',
        school_id: '',
        name: userRegistration.name ?? '',
        google_domain_admin_email: userRegistration.email ?? '',
        apply_to_child: false,
        language: userLang,
        entity_id: '',
        registration_id: regId
      }
    } else {
      return initialValue
    }
  }, [regId, userRegistration])

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset
  } = useForm({
    resolver: yupResolver(regId ? setupSchema : invitationScheme),
    defaultValues
  })

  const onSubmit = async values => {
    mutate(values, {
      onSuccess: data => {
        enqueueSnackbar({
          message: data?.data?.message ?? 'Successfull Invite Google domain',
          variant: 'success'
        })
        navigate({ pathname: ADMIN_OVERVIEW })
      },
      onError: error => {
        enqueueSnackbar({
          message: parseApiErrorMessage(error),
          variant: 'error'
        })
      }
    })
  }

  const schoolName = regId
    ? `${t(`${localization}.userSchool`)}: ${userRegistration?.organisation_name}`
    : ''

  useEffect(() => {
    if (userRegistration && !userRegistration.is_google) {
      navigate({ pathname: ADMIN_INVITE_GOOGLE_DOMAIN })
    }
  }, [navigate, userRegistration])

  // To handle update and reset form
  useEffect(() => {
    if (regId === null) {
      reset(initialValue)
    } else {
      reset(defaultValues)
    }
  }, [defaultValues, regId, reset])

  if (isLoadingReg) {
    return <FullPageLoading />
  }

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb'>
        <Typography>{t('general.inviteGoogleDomain')}</Typography>
      </Breadcrumbs>
      <Divider />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ mb: 2, mt: 5 }}>
          <Typography>
            {t(`${localization}.hostedDomain`)}
            <span style={{ color: 'red' }}>*</span>
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
            <TextField
              {...register('hosted_domain')}
              fullWidth
              variant='outlined'
              error={!!errors.hosted_domain}
              helperText={<>{errors.hosted_domain?.message}</>}
            />
          </Box>
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography>
            {t(`${localization}.name`)}
            <span style={{ color: 'red' }}>*</span>
          </Typography>
          <TextField
            {...register('name')}
            fullWidth
            variant='outlined'
            error={!!errors.name}
            helperText={<>{errors.name?.message}</>}
          />
        </Box>

        <CompanySchoolFormSelector
          errors={errors}
          control={control}
          orgData={orgData}
          schoolName={schoolName}
          setValue={setValue}
        />

        <Box sx={{ mb: 2 }}>
          <FormControlLabel
            control={
              <Controller name='apply_to_child' control={control} render={CheckboxComponent} />
            }
            style={{ fontStyle: 'italic' }}
            label={t('pages.adminOverview.applyToChild')}
          />
        </Box>

        {!regId && (
          <Box sx={{ mb: 2 }}>
            <Typography>
              {t(`${localization}.domainAdminEmail`)}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              {...register('google_domain_admin_email')}
              fullWidth
              variant='outlined'
              error={!!errors.google_domain_admin_email}
              helperText={<>{errors.google_domain_admin_email?.message}</>}
            />
          </Box>
        )}

        <Box sx={{ mb: 2 }}>
          <Typography> {t(`${localization}.language`)}</Typography>
          <Controller
            render={({ field: { onChange }, ...props }) => (
              <Autocomplete
                value={language}
                options={languages.map(option => option)}
                onChange={(e, value) => {
                  onChange(value.id)
                  setLanguage(value)
                }}
                sx={{ mt: 1 }}
                renderInput={params => (
                  <TextField {...params} label={t(`${localization}.language`)} />
                )}
              />
            )}
            defaultValue={language?.id}
            name={'language'}
            control={control}
          />
        </Box>

        <Stack direction='row-reverse'>
          <LoadingButton
            variant='contained'
            type='submit'
            loading={isLoading}
            onClick={handleSubmit}
          >
            {!regId ? t('button.invite') : t('button.setup')}
          </LoadingButton>
        </Stack>
      </form>
    </>
  )
}

export default InviteGoogleDomain
