import { Breadcrumbs, Divider, Typography } from '@mui/material'
import propTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PaginationTable from '~/components/PaginationTable'
import useTable from '~/hooks/useTable'
import { isOverviewPage } from '~/utils/helpers'
import TableFilters from '../Table/TableFilters'
import RegisterSessionTable from './RegisterSessionTable'

const RegisterSessionLog = ({ query, orgData, entitiesData, tenants }) => {
  const { t } = useTranslation()

  const [orgFilter, setOrgFilter] = useState('')
  const [entityFilter, setEntityFilter] = useState('')
  const [tenantFilter, setTenantFilter] = useState('')

  const {
    data,
    search,
    isLoading,
    order,
    page,
    orderBy,
    searchHandler,
    sortHandler,
    changePageHandler,
    setPage
  } = useTable({
    query,
    initialStates: {
      order: 'desc',
      page: 1,
      orderBy: 'created_at'
    },
    queryAttributes: {
      external_id: orgFilter?.external_id,
      entity_id: entityFilter?.id,
      tenant_id: tenantFilter?.id
    }
  })

  const entities = useMemo(() => {
    if (entitiesData) {
      return entitiesData.map(option => ({
        label: option.name + ' (' + option.entity_tid + ')',
        id: option.id
      }))
    }
    return []
  }, [entitiesData])

  const orgs = useMemo(() => {
    if (entityFilter?.id && orgData) {
      return orgData.filter(option => option.entity_id === entityFilter.id)
    }
    return orgData || []
  }, [entityFilter?.id, orgData])

  useEffect(() => {
    if (entityFilter?.id) {
      if (orgFilter?.entity_id !== entityFilter.id) {
        setOrgFilter('') // reset filter
      }
    }
  }, [entityFilter?.id, orgFilter?.entity_id])

  useEffect(() => {
    if (orgFilter || entityFilter || tenantFilter) {
      setPage(1) // reset page
    }
  }, [orgFilter, entityFilter, tenantFilter, setPage])

  const autocompleteGroup = useMemo(() => {
    return [
      {
        label: 'Entity',
        setValue: setEntityFilter,
        dataSet: entities,
        value: entityFilter,
        disabled: false,
        hidden: false
      },
      {
        label: t('pages.organisation.organisation'),
        setValue: setOrgFilter,
        dataSet: orgs,
        value: orgFilter,
        disabled: false,
        hidden: false
      },
      {
        label: 'Tenant',
        setValue: setTenantFilter,
        dataSet: tenants,
        value: tenantFilter,
        disabled: false,
        hidden: !isOverviewPage()
      }
    ]
  }, [entities, entityFilter, orgFilter, orgs, t, tenantFilter, tenants])

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb'>
        <Typography>{t('general.registerSessionOverview')}</Typography>
      </Breadcrumbs>
      <Divider />
      <TableFilters
        {...{
          autocompleteGroup,
          search,
          searchHandler
        }}
        onEnterSearch={searchHandler}
        showDatePicker={true}
        searchPlaceHolder={t('general.searchFor') + t('general.serials_or_State')}
        page='register-session-logs'
      />
      <Divider />
      <RegisterSessionTable
        order={order}
        orderBy={orderBy}
        isLoading={isLoading}
        handleRequestSort={sortHandler}
        data={data}
      />
      <PaginationTable data={data} page={page} size='medium' handleChangePage={changePageHandler} />
    </>
  )
}

RegisterSessionLog.propTypes = {
  query: propTypes.func.isRequired,
  orgData: propTypes.array,
  entitiesData: propTypes.array,
  tenants: propTypes.array
}

RegisterSessionLog.defaultProps = {
  orgData: [],
  entitiesData: [],
  tenants: []
}

export default RegisterSessionLog
