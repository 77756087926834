import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ToolBarButton from '~/components/Button/ToolBarButton'
import ListToolbarWrapper from './ListToolbarWrapper'

const ListToolbar = ({ toolBarButton }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <ListToolbarWrapper>
      {toolBarButton &&
        toolBarButton.map((item, index) => (
          <ToolBarButton
            key={index}
            icon={item.icon}
            handleAction={e => {
              if (item.navigateLink) navigate(`${item.navigateLink}`)
              else {
                item.onClick(e)
              }
            }}
            name={t(`button.${item.button}`)}
            disabled={item.disabled}
            loading={item.loading}
          />
        ))}
    </ListToolbarWrapper>
  )
}

ListToolbar.propTypes = {
  toolBarButton: PropTypes.array
}

export default ListToolbar
