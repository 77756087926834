import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import {
  ADMIN_ACTIVITY_LOGS,
  ADMIN_ACTIVITY_LOG_DETAIL,
  ADMIN_AZURE_DEVICE_DETAIL,
  ADMIN_DASHBOARD,
  ADMIN_DEVICES,
  ADMIN_DEVICE_REGISTER_SESSIONS,
  ADMIN_GOOGLE_DEVICE_DETAIL,
  ADMIN_INVITE_AZURE_TENANTS,
  ADMIN_INVITE_GOOGLE_DOMAIN,
  ADMIN_OVERVIEW,
  ADMIN_REGISTRATION,
  ADMIN_REGISTRATION_DETAIL,
  ADMIN_SETTING,
  ADMIN_SUPER_USERS,
  ADMIN_SUPER_USERS_DISABLE,
  ADMIN_USERS,
  ADMIN_USERS_DISABLE,
  ADMIN_USER_COMPANY,
  ADMIN_USER_COMPANY_DETAIL,
  ADMIN_USER_COMPANY_EDIT,
  ADMIN_USER_COMPANY_NEW,
  ADMIN_USER_ROLE,
  ADMIN_USER_ROLE_DETAIL,
  ADMIN_USER_ROLE_NEW,
  ADMIN_USER_SETTING,
  ADMIN_VIEW_SUPER_USER_DETAIL,
  ADMIN_VIEW_USER_DETAIL,
  AZURE_DEVICES,
  AZURE_DEVICE_DETAIL,
  AZURE_DEVICE_NEW,
  DASHBOARD,
  DEVICE_LOGS,
  GOOGLE_DEVICES,
  GOOGLE_DEVICE_DETAIL,
  GOOGLE_DEVICE_NEW,
  GROUPTAGS,
  HEALTH_CHECK,
  ORGANISATION,
  ORG_OVERVIEW,
  OVERVIEW_DEVICE_REGISTER_SESSION_DETAIL,
  OVERVIEW_TENANTS_DETAIL,
  PRE_PROVISIONING_TOKEN,
  USER_SETTING
} from '~/constants/Routes'
import InviteAzureTenant from '~/pages/Admin/Administrator/InviteAzureTenants'
import InviteGoogleDomain from '~/pages/Admin/Administrator/InviteGoogleDomain'
import UserProfile from '~/pages/Admin/Administrator/Users/Detail'
import DeviceOverview from '~/pages/Admin/Device'
import AdminAzureDevice from '~/pages/Admin/Device/Azure/DeviceDetail/AdminAzureDevice'
import AdminGoogleDevice from '~/pages/Admin/Device/GoogleDeviceDetail/AdminGoogleDevice'

import { useAdminPermission } from '~/hooks/useAdminPermission'
import { useUserPermission } from '~/hooks/useUserPermission'
import OverviewUsers from '~/pages/Admin/Administrator/Admins'
import SuperUserProfile from '~/pages/Admin/Administrator/Admins/Detail'
import DisableSuperUsers from '~/pages/Admin/Administrator/Admins/DisableUsers'
import RegistrationTable from '~/pages/Admin/Administrator/Registrations'
import RegistrationDetail from '~/pages/Admin/Administrator/Registrations/RegistrationDetail'
import OverviewSettings from '~/pages/Admin/Administrator/Settings'
import Users from '~/pages/Admin/Administrator/Users'
import DisableUsers from '~/pages/Admin/Administrator/Users/DisableUsers'
import CompanyOverview from '~/pages/Admin/Company'
import Detail from '~/pages/Admin/Company/Detail'
import Edit from '~/pages/Admin/Company/Edit'
import New from '~/pages/Admin/Company/New'
import AdminDashboard from '~/pages/Admin/Dashboard'
import OverviewRegisterSession from '~/pages/Admin/OverviewRegisterSession'
import OverviewDeviceRegisterSessionDetail from '~/pages/Admin/OverviewRegisterSession/OverviewDeviceRegisterSessionDetail'
import Role from '~/pages/Admin/Role/Role'
import RoleCreate from '~/pages/Admin/Role/RoleCreate'
import RoleDetail from '~/pages/Admin/Role/RoleDetail'
import OverviewHealthCheck from '~/pages/Admin/Tenant/OverviewHealthCheck'
import Overview from '~/pages/Admin/overview'
import Dashboard from '~/pages/Dashboard'
import AzureDevice from '~/pages/Device/Detail/AzureDevice'
import GoogleDevice from '~/pages/Device/Detail/GoogleDevice'
import RegisterAzureDevice from '~/pages/Device/New/Register/RegisterAzureDevice'
import RegisterGoogleDevice from '~/pages/Device/New/Register/RegisterGoogleDevice'
import AzureDeviceTable from '~/pages/Device/Overview/DeviceOverViewTable/AzureDeviceTable'
import GoogleDeviceTable from '~/pages/Device/Overview/DeviceOverViewTable/GoogleDeviceTable'
import DeviceRegisterSession from '~/pages/DeviceRegisterSession'
import HealthCheckPage from '~/pages/HealthCheck'
import GroupTags from '~/pages/Organisations/GroupTag'
import Organisations from '~/pages/Organisations/Organisations'
import OrganisationsOverview from '~/pages/Organisations/Overview'
import ProvisionTokensTable from '~/pages/Organisations/PreprovisionToken'
import UserSetting from '~/pages/Setting'
import { selectUser } from '~/store/auth/selector'
import Layout from './layout'
import ActivityLogs from '~/pages/Admin/Administrator/ActivityLogs'
import ActivityLogsDetails from '~/pages/Admin/Administrator/ActivityLogs/Detail'

const Navigator = () => {
  const user = useSelector(selectUser)
  const navigate = useNavigate()

  useEffect(() => {
    const redirectPath = localStorage.getItem('REDIRECT_URL')
    if (redirectPath) {
      localStorage.removeItem('REDIRECT_URL')
      navigate(redirectPath)
    }
  })

  const { hasPermission } = useAdminPermission()
  const { hasPermission: userHasPermission } = useUserPermission()

  const overviewRoutes = useMemo(() => {
    if (!hasPermission) {
      return null
    }

    return (
      <>
        <Route path={ADMIN_USER_SETTING} element={<UserSetting />} />
        <Route path={ADMIN_OVERVIEW} element={<Overview />} />
        <Route path={OVERVIEW_TENANTS_DETAIL} element={<OverviewHealthCheck />} />
        <Route path={ADMIN_DEVICES} element={<DeviceOverview />} />
        <Route path={ADMIN_GOOGLE_DEVICE_DETAIL} element={<AdminGoogleDevice />} />
        <Route path={ADMIN_AZURE_DEVICE_DETAIL} element={<AdminAzureDevice />} />
        <Route path={ADMIN_DEVICE_REGISTER_SESSIONS} element={<OverviewRegisterSession />} />
        <Route path={ADMIN_USER_COMPANY} element={<CompanyOverview />} />
        <Route path={ADMIN_USER_COMPANY_NEW} element={<New />} />
        <Route path={ADMIN_USER_COMPANY_EDIT} element={<Edit />} />
        <Route path={ADMIN_USER_COMPANY_DETAIL} element={<Detail />} />
        <Route path={ADMIN_USER_ROLE} element={<Role />} />
        <Route path={ADMIN_REGISTRATION} element={<RegistrationTable />} />
        <Route path={ADMIN_REGISTRATION_DETAIL} element={<RegistrationDetail />} />
        <Route
          path={OVERVIEW_DEVICE_REGISTER_SESSION_DETAIL}
          element={<OverviewDeviceRegisterSessionDetail />}
        />
        <Route path={ADMIN_USER_ROLE_DETAIL} element={<RoleDetail />} />
        <Route path={ADMIN_USER_ROLE_NEW} element={<RoleCreate />} />
        <Route path={ADMIN_INVITE_AZURE_TENANTS} element={<InviteAzureTenant />} />
        <Route path={ADMIN_INVITE_GOOGLE_DOMAIN} element={<InviteGoogleDomain />} />
        <Route path={ADMIN_USERS} element={<Users />} />
        <Route path={ADMIN_USERS_DISABLE} element={<DisableUsers />} />
        <Route path={ADMIN_VIEW_USER_DETAIL} element={<UserProfile />} />
        <Route path={ADMIN_SUPER_USERS} element={<OverviewUsers />} />
        <Route path={ADMIN_SUPER_USERS_DISABLE} element={<DisableSuperUsers />} />
        <Route path={ADMIN_VIEW_SUPER_USER_DETAIL} element={<SuperUserProfile />} />
        <Route path={ADMIN_SETTING} element={<OverviewSettings />} />
        <Route path={ADMIN_ACTIVITY_LOGS} element={<ActivityLogs />} />
        <Route path={ADMIN_ACTIVITY_LOG_DETAIL} element={<ActivityLogsDetails />} />
      </>
    )
  }, [hasPermission])

  const serviceRoutes = useMemo(() => {
    if (!userHasPermission) {
      return null
    }

    if (user?.is_azure) {
      return (
        <>
          <Route path={AZURE_DEVICES} element={<AzureDeviceTable />} />
          <Route path={AZURE_DEVICE_NEW} element={<RegisterAzureDevice />} />
          <Route path={AZURE_DEVICE_DETAIL} element={<AzureDevice />} />
          <Route path={GROUPTAGS} element={<GroupTags />} />
        </>
      )
    }
    if (user?.is_google) {
      return (
        <>
          <Route path={GOOGLE_DEVICES} element={<GoogleDeviceTable />} />
          <Route path={GOOGLE_DEVICE_NEW} element={<RegisterGoogleDevice />} />
          <Route path={GOOGLE_DEVICE_DETAIL} element={<GoogleDevice />} />
          <Route path={PRE_PROVISIONING_TOKEN} element={<ProvisionTokensTable />} />
        </>
      )
    }
    return null
  }, [user, userHasPermission])

  const sharedRoutes = useMemo(() => {
    if (!userHasPermission) {
      return null
    }

    return (
      <>
        <Route path={DEVICE_LOGS} element={<DeviceRegisterSession />} />
        <Route path={HEALTH_CHECK} element={<HealthCheckPage />} />
        <Route path={ORGANISATION} element={<Organisations />} />
        <Route path={GROUPTAGS} element={<GroupTags />} />
        <Route path={ORG_OVERVIEW} element={<OrganisationsOverview />} />
      </>
    )
  }, [userHasPermission])

  return (
    <Layout>
      <Routes>
        {serviceRoutes}
        {overviewRoutes}
        {sharedRoutes}
        <Route path={USER_SETTING} element={<UserSetting />} />
        <Route path={DASHBOARD} element={<Dashboard />} />
        <Route path={ADMIN_DASHBOARD} element={<AdminDashboard />} />
        <Route path='/overview/*' element={<Navigate to={ADMIN_DASHBOARD} />} />
        <Route path='*' element={<Navigate to={DASHBOARD} />} />
      </Routes>
    </Layout>
  )
}

export default Navigator
