import { Check, Key, SecurityTwoTone, Sensors, SettingsApplications } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Breadcrumbs, Switch, Tooltip, Typography } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import { parse } from 'qs'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { isOverviewPage, showErrorMessage } from '~/utils/helpers'
import { grantPermissionRedirect } from '~/utils/msal'
import { useGrantAppPermissionMutation } from '../Registration/mutation'
import GoogleHealthCheck from './GoogleHealthCheck/GoogleHealthCheck'
import HealthCheckBox from './HealthCheckBox'
import ReRegisterApplication from './RegisterApp/ReRegisterApplication'
import SchoolSection from './SchoolSection'
import LaptopIcon from '@mui/icons-material/Laptop'
import { useSwitchDeviceNameFlowForTeantMutation } from './mutation'

export const FETCHING_HEALTHCHECK_TENANT_INTERVAL_KEY = 'FETCHING_HEALTHCHECK_TENANT_INTERVAL'
export const FETCHING_HEALTHCHECK_TENANT_INTERVAL = 3000 // 3 seconds

const HealthCheck = ({
  tenantId,
  getTenantInfoApi,
  healthCheckApi,
  healthCheckSyncDataApi,
  syncDevicesApi,
  grantApplicationPermissionApi,
  canGrantPermission
}) => {
  const [refetchInterval, setRefetchInterval] = useState(() =>
    Number(localStorage.getItem(FETCHING_HEALTHCHECK_TENANT_INTERVAL_KEY))
  )

  const { data: tenant } = useQuery({
    queryKey: ['tenant_details', tenantId, refetchInterval],
    queryFn: async () => await getTenantInfoApi(tenantId),
    select: response => response.data.data,
    onError: showErrorMessage,
    refetchInterval
  })

  const { mutate: toggleSetDeviceName, isLoading } =
    useSwitchDeviceNameFlowForTeantMutation(tenantId)

  const serviceTenant = tenant?.serviceTenant
  const isAzureTenant = tenant?.service_type === 'App\\Models\\AzureTenant'

  const { t } = useTranslation()
  const { data: healthCheck } = useQuery(
    ['health_check_statuses', tenantId, refetchInterval],
    async () => {
      const { data } = await healthCheckApi(tenantId)
      return data
    },
    {
      refetchInterval,
      enabled: isAzureTenant
    }
  )

  const queryClient = useQueryClient()

  const healthCheckMutation = useMutation({
    mutationFn: async tId => await healthCheckSyncDataApi(tId),
    onSuccess: data => {
      enqueueSnackbar({
        message: data?.data?.message ?? 'Health check is updated!',
        variant: 'success'
      })
      queryClient.invalidateQueries('health_check_statuses')
    },
    onError: showErrorMessage
  })

  const syncDeviceMutation = useMutation(
    async id => {
      const { data } = await syncDevicesApi(id)
      return data
    },
    {
      onSuccess: data => {
        enqueueSnackbar({
          message: data?.data?.message ?? 'Device sync',
          variant: 'success'
        })
        queryClient.invalidateQueries('tenant_details')
      }
    }
  )

  const [suspendAppRegister, setSuspendAppRegister] = useState(false)
  const grantApplicationPermissionMutation = useMutation({
    mutationFn: async id => await grantApplicationPermissionApi(id),
    onSuccess: () => {
      enqueueSnackbar({
        message:
          'Succesfully granting permission, the health check for permission will be updated soon',
        variant: 'success'
      })
    },
    onError: showErrorMessage
  })

  /**
   * Keep loading the registration button for 1 minutes
   */
  const suspendTimeout = useRef()
  useEffect(() => {
    if (suspendAppRegister) {
      suspendTimeout.current = setTimeout(() => {
        setSuspendAppRegister(() => false)
        enqueueSnackbar({
          message: 'The application registration seems to be failed, please try again',
          variant: 'error'
        })
      }, 60000)
    }
  }, [suspendAppRegister])

  useEffect(() => {
    if (serviceTenant?.tenant_id) {
      setSuspendAppRegister(false)
      clearTimeout(suspendTimeout.current)
    }
  }, [serviceTenant])

  useEffect(() => {
    if (!isAzureTenant) {
      setRefetchInterval(0)
    }
  }, [isAzureTenant])

  const permissionMutation = useGrantAppPermissionMutation()

  const [toogle, setToogle] = useState(refetchInterval > 0)
  const toogleRefreshButton = useCallback(
    v => {
      setToogle(v)
      const interval = toogle ? 0 : FETCHING_HEALTHCHECK_TENANT_INTERVAL
      setRefetchInterval(interval)
      localStorage.setItem(FETCHING_HEALTHCHECK_TENANT_INTERVAL_KEY, interval)
    },
    [toogle, setToogle]
  )

  /**
   * Check a redirect from microsoft grant permission flow and send an update to backend if needed
   */
  const queryPrams = parse(window.location.search, { ignoreQueryPrefix: true })
  useEffect(() => {
    const consentState = localStorage.getItem('GRANT_PERMISSION_STATE')
    if (
      consentState != null &&
      queryPrams.admin_consent === 'True' &&
      consentState === queryPrams.state
    ) {
      grantApplicationPermissionMutation.mutate(tenantId)
      localStorage.removeItem('GRANT_PERMISSION_STATE')
    }
  }, [grantApplicationPermissionMutation, queryPrams, tenantId])

  const availablePermissions = useMemo(() => {
    if (healthCheck == null || !healthCheck.permissionCheck?.is_success) {
      return null
    }

    return healthCheck?.availablePermissions?.map(item => {
      return (
        <Box sx={{ display: 'flex', width: '100%' }} key={item}>
          <Check sx={{ color: '#76B72A' }} /> <Typography>{item}</Typography>
        </Box>
      )
    })
  }, [healthCheck])

  if (tenant == null || (healthCheck == null && isAzureTenant)) {
    return null
  }

  const renderAzureHealthCheck = () => {
    const showGrantPermissionButton =
      !healthCheck.isLostApplicationConnection &&
      !healthCheck.permissionCheck?.is_success &&
      canGrantPermission

    const showSyncDevicesButton = !healthCheck.isLostApplicationConnection
    return (
      <>
        <Breadcrumbs aria-label='breadcrumb'>
          <Typography>{t('general.healthCheck')}</Typography>
        </Breadcrumbs>

        <Tooltip title='Click on this button to check Permissions, Last connection and Autopilot CNAME'>
          <LoadingButton
            onClick={() => healthCheckMutation.mutate(tenantId)}
            sx={{ mt: 1, mr: 1 }}
            loading={healthCheckMutation.isLoading}
            variant='contained'
          >
            {t('general.healthCheck')}
          </LoadingButton>
        </Tooltip>

        <HealthCheckBox
          icon={<SettingsApplications />}
          title={t('pages.healthCheck.applicationConnection')}
          data={healthCheck.applicationConnectionCheck}
        >
          <ReRegisterApplication
            tenantId={tenantId}
            visible={healthCheck.isLostApplicationConnection}
            suspendAppRegister={suspendAppRegister}
            onSuccess={() => {
              setSuspendAppRegister(true)
              if (!toogle) {
                toogleRefreshButton(true)
              }
            }}
          />
        </HealthCheckBox>

        <HealthCheckBox
          icon={<Key />}
          title={t('pages.healthCheck.permissions')}
          data={healthCheck.permissionCheck}
          extraInfo={availablePermissions}
        >
          {showGrantPermissionButton && (
            <LoadingButton
              onClick={() =>
                grantPermissionRedirect(
                  serviceTenant?.tenant_id,
                  `${window.location.origin}/health-check`
                )
              }
              sx={{ mt: 1, mr: 1 }}
              loading={permissionMutation.isLoading}
              variant='contained'
            >
              {t('general.grantAppPermission')}
            </LoadingButton>
          )}
        </HealthCheckBox>

        <HealthCheckBox
          icon={<Sensors />}
          title={t('pages.healthCheck.lastConnection')}
          data={healthCheck.lastConnectionCheck}
        >
          {showSyncDevicesButton && (
            <LoadingButton
              onClick={() =>
                syncDeviceMutation.mutate(serviceTenant?.id, {
                  onSuccess: () => {
                    queryClient.invalidateQueries('tenant_details')
                  }
                })
              }
              sx={{ mt: 1, mr: 1 }}
              loading={serviceTenant?.is_capturing_tenant_devices}
              variant='contained'
            >
              {!serviceTenant?.is_capturing_tenant_devices
                ? t('general.syncDevice')
                : t('general.syncingDevice')}
            </LoadingButton>
          )}
        </HealthCheckBox>

        <HealthCheckBox
          icon={<SecurityTwoTone />}
          title={t('pages.healthCheck.cname')}
          data={healthCheck.cnameCheck}
        />

        {tenant.is_azure && isOverviewPage() && (
          <HealthCheckBox
            icon={<LaptopIcon />}
            title={t('pages.organisation.setDeviceName')}
            data={''}
          >
            <>
              <Switch
                checked={tenant?.serviceTenant?.enable_set_device_name}
                onChange={event => toggleSetDeviceName()}
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={isLoading}
              />
              {t('pages.tenant.enableSetDeviceName')}
            </>
          </HealthCheckBox>
        )}

        <SchoolSection tenant={tenant} />
      </>
    )
  }

  return isAzureTenant ? renderAzureHealthCheck() : <GoogleHealthCheck tenant={tenant} />
}

HealthCheck.propTypes = {
  tenantId: PropTypes.number.isRequired,
  getTenantInfoApi: PropTypes.func.isRequired,
  healthCheckApi: PropTypes.func.isRequired,
  healthCheckSyncDataApi: PropTypes.func.isRequired,
  syncDevicesApi: PropTypes.func.isRequired,
  grantApplicationPermissionApi: PropTypes.func.isRequired,
  canGrantPermission: PropTypes.bool
}

HealthCheck.defaultProps = {
  canGrantPermission: false
}

export default HealthCheck
