import styled from '@emotion/styled'
import { CardHeader } from '@mui/material'

const CustomCardHeader = styled(CardHeader)(({ theme }) => ({
  color: `${theme.palette.blue[100]}`,
  transition: '0.5s',
  '&:hover': {
    color: `${theme.palette.green[100]}`
  }
}))

export default CustomCardHeader
