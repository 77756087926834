import { Tab, Tabs } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import BreadCrumb from '~/components/BreadCrumb'
import FullPageLoading from '~/components/FullPageLoading'
import { ADMIN_USER_ROLE } from '~/constants/Routes'
import Body from './Body'
import { useGetAllPermissionsQuery, useRoleDetailQuery } from './query'
import RoleUser from './ConnectedUsersTag/RoleMemberAccordion'
import { USER_ACCOUNT } from '~/constants/constants'

const tabList = [
  {
    index: 0,
    name: 'permissions'
  },
  {
    index: 1,
    name: 'connectedUsers'
  }
]

const localization = 'pages.overview.roleDetail'

const RoleDetail = () => {
  const urlParams = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [tabValue, setTabValue] = useState(0)

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }
  const { data: queryResponse, isLoading } = useRoleDetailQuery({ id: urlParams.id })

  const { data: permissions } = useGetAllPermissionsQuery(queryResponse?.guard_name)

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (!location.search) {
      searchParams.set('tag', '0')
      const newSearchString = searchParams.toString()
      navigate({
        pathname: window.location.pathname,
        search: newSearchString
      })
    } else if (searchParams.has('tag')) {
      const tabIndex = parseInt(searchParams.get('tag'))

      if (tabIndex > 1 || tabIndex < 0 || isNaN(tabIndex)) {
        searchParams.set('tag', '0')
        const newSearchString = searchParams.toString()
        navigate({
          pathname: window.location.pathname,
          search: newSearchString
        })
      }

      const obj = tabList.some(t => t.index === tabIndex)
      if (obj) {
        setTabValue(tabIndex)
      } else {
        setTabValue(0)
      }
    }
  }, [navigate])

  if (isLoading) {
    return <FullPageLoading />
  }

  // update breadCrumb
  const BreadComponents = [
    { title: 'Overview' },
    {
      route: ADMIN_USER_ROLE,
      title: 'Roles'
    },
    {
      page: 'detail',
      title: queryResponse.name
    }
  ]

  return (
    <>
      <BreadCrumb breadcrumbs={BreadComponents} />
      {permissions && queryResponse && (
        <>
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label={t(`${localization}.permissions`)} />
            <Tab label={t(`${localization}.connectedUsers`)} />
          </Tabs>
          {tabValue === 0 && (
            <>
              <Body permissions={permissions} useRoleData={queryResponse}></Body>
            </>
          )}
          {tabValue === 1 && <RoleUser guardName={queryResponse?.guard_name ?? USER_ACCOUNT} />}
        </>
      )}
    </>
  )
}
export default RoleDetail
