import {
  Box,
  Breadcrumbs,
  Divider,
  Grid,
  Paper,
  Table,
  TableContainer,
  Toolbar,
  Typography
} from '@mui/material'
import PropTypes from 'prop-types'
import PaginationTable from '~/components/PaginationTable'
import SearchToolBar from '~/components/SearchToolBar'
import TableHeader from '~/components/TableHeader'
import useTable from '~/hooks/useTable'
import OverviewUsersTableBody from './OverviewUsersTableBody'
import { UserHeadCells } from '../UserHeadCells'

const localization = 'pages.adminOverview.users.'

const OverviewUsersTable = ({ query, title, queryAttributes }) => {
  const {
    data,
    search,
    isLoading,
    order,
    page,
    orderBy,
    searchHandler,
    sortHandler,
    changePageHandler
  } = useTable({
    query,
    initialStates: {
      order: 'desc',
      page: 1,
      orderBy: 'created_at'
    },
    queryAttributes
  })

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb'>
        <Typography>{title}</Typography>
      </Breadcrumbs>
      <Divider />
      <Paper elevation={1}>
        <Toolbar>
          <Grid container spacing={0.5}>
            <Box sx={{ flexGrow: 1 }} />
            <Grid item sx={12} sm={3}>
              <SearchToolBar filterSearch={search} onChange={searchHandler} placeholder='users' />
            </Grid>
          </Grid>
        </Toolbar>
        <Divider />

        <TableContainer>
          <Table>
            <TableHeader
              headCells={UserHeadCells}
              onRequestSort={sortHandler}
              order={order}
              orderBy={orderBy}
              localization={localization}
            />
            <OverviewUsersTableBody isLoaing={isLoading} users={data} />
          </Table>
        </TableContainer>
        <PaginationTable
          data={data}
          page={page}
          size='medium'
          handleChangePage={changePageHandler}
        />
      </Paper>
    </>
  )
}

OverviewUsersTable.propTypes = {
  query: PropTypes.func.isRequired,
  title: PropTypes.string,
  queryAttributes: PropTypes.object
}

OverviewUsersTable.defaultProps = {
  queryAttributes: {},
  title: ''
}

export default OverviewUsersTable
