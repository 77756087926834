import { enqueueSnackbar } from 'notistack'
import { useQuery } from 'react-query'
import {
  adminGetAzureDevice,
  adminGetGoogleDevice,
  adminListAzureDevices,
  adminListGoogleDevices
} from '~/api'
import { parseApiErrorMessage, removeEmptyValue } from '~/utils/helpers'

export const useAzureDeviceListOverviewQuery = (params, options = {}) => {
  return useQuery(
    ['overview_azure_devices', params],
    async () => {
      const p = removeEmptyValue(params)
      const { data } = await adminListAzureDevices(p)
      return { data: data.data, total: data.meta.total }
    },
    {
      refetchInterval: options.refetchInterval,
      onError: e => {
        const message = parseApiErrorMessage(e)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      },
      retry: false
    }
  )
}

export const useGoogleDeviceListOverviewQuery = (params, options = {}) => {
  return useQuery(
    ['overview_google_devices', params],
    async () => {
      const p = removeEmptyValue(params)
      const { data } = await adminListGoogleDevices(p)
      return { data: data.data, total: data.meta.total }
    },
    {
      refetchInterval: options.refetchInterval,
      onError: e => {
        const message = parseApiErrorMessage(e)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      },
      retry: false
    }
  )
}

export const useAzureDeviceDetailQuery = id => {
  return useQuery(['overview_azure_device_detail', id], async () => {
    const { data } = await adminGetAzureDevice(id)
    return data
  })
}

export const useGoogleDeviceDetailQuery = id => {
  return useQuery(['overview_google_device_detail', id], async () => {
    const { data } = await adminGetGoogleDevice(id)
    return data
  })
}
