import { Autocomplete, FormControl, Grid, TextField } from '@mui/material'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { setFilterObject } from '~/store/filter/slice'

const AutoCompleteTable = ({ filterObject, page, disabled = false }) => {
  const dispatch = useDispatch()

  const onChange = useCallback(
    (e, newValue) => {
      dispatch(
        setFilterObject({
          page,
          filter: {
            name: filterObject.label,
            value: newValue
          }
        })
      )
      filterObject.setValue(newValue)
    },
    [filterObject, dispatch, page]
  )

  if (filterObject.hidden) {
    return <></>
  }

  return (
    <Grid item xs={12} sm={filterObject.sm || 2}>
      <FormControl sx={{ m: 1, width: '100%' }}>
        <Autocomplete
          options={filterObject.dataSet || []}
          value={filterObject.value}
          isOptionEqualToValue={(option, value) => option.id === value}
          renderInput={params => <TextField {...params} label={filterObject.label} />}
          onChange={onChange}
        />
      </FormControl>
    </Grid>
  )
}

AutoCompleteTable.propTypes = {
  filterObject: PropTypes.shape({
    label: PropTypes.string.isRequired,
    dataSet: PropTypes.array,
    value: PropTypes.object,
    setValue: PropTypes.func,
    sm: PropTypes.number,
    hidden: PropTypes.bool
  }).isRequired,
  page: PropTypes.string,
  disabled: PropTypes.bool
}

AutoCompleteTable.defaultProps = {
  filterObject: {
    hidden: false,
    dataSet: [],
    value: null,
    sm: 2
  }
}

export default AutoCompleteTable
