import ReactDOM from 'react-dom/client'
import App from './App'
import './i18n'
import './index.css'
import * as Sentry from '@sentry/react'

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_NODE_ENV
  })
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />)
