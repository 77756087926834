import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Box, Chip, IconButton, Link, TableBody, TableCell, TableRow, Tooltip } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import DataNotFound from '~/components/DataNotFound'
import DateTimeLocale from '~/components/DateTimeLocale'
import TableLoading from '~/components/Table/TableLoading'
import { useAdminPermission } from '~/hooks/useAdminPermission'
import { getUserStatusColor } from '~/utils/helpers'
import { UserHeadCells } from '../UserHeadCells'

const UsersTableBody = ({ isLoading, users }) => {
  const { overviewUser } = useAdminPermission()
  const copyValue = useCallback(e => {
    navigator.clipboard.writeText(e)
    enqueueSnackbar({
      message: 'Copied to clipboard',
      variant: 'info'
    })
  }, [])

  if (isLoading) {
    return <TableLoading colSpan={UserHeadCells.length} />
  }

  const renderIdCol = row =>
    overviewUser.administrator.users.canViewDetail ? (
      <Link href={`/overview/users/${row.id}`} sx={{ ml: 2 }}>
        {row.id}
      </Link>
    ) : (
      row.id
    )

  return (
    <TableBody>
      {users?.data?.length === 0 && <DataNotFound colSpan={UserHeadCells.length} />}
      {users?.data?.length > 0 &&
        users?.data?.map(row => {
          const chipcolor = getUserStatusColor(row?.status)
          return (
            <TableRow key={row.id}>
              <TableCell>{renderIdCol(row)}</TableCell>
              <TableCell>{row?.name}</TableCell>
              <TableCell>{row?.email}</TableCell>
              <TableCell>{row?.role}</TableCell>
              <TableCell
                sx={{
                  textAlign: 'center'
                }}
              >
                <Tooltip title={<DateTimeLocale datetime={row?.last_signed_in} />}>
                  <Box>{row?.last_signed_in_at}</Box>
                </Tooltip>
              </TableCell>
              <TableCell>{row?.ip_address}</TableCell>
              <TableCell>
                <Chip label={row?.status} color={chipcolor} size='small' />
              </TableCell>
              <TableCell>
                {row?.registration_token && (
                  <>
                    {row?.registered_at}
                    {row?.registration_token}
                    <IconButton onClick={e => copyValue(row?.registration_link)}>
                      <ContentCopyIcon />
                    </IconButton>
                  </>
                )}
              </TableCell>
              <TableCell>
                <DateTimeLocale datetime={row?.created_at} />
              </TableCell>
            </TableRow>
          )
        })}
    </TableBody>
  )
}

UsersTableBody.defaultProps = {
  isLoading: false,
  users: {}
}

UsersTableBody.propTypes = {
  isLoading: PropTypes.bool,
  users: PropTypes.object
}

export default UsersTableBody
