import { styled } from '@mui/material/styles'
import MuiToggleButton from '@mui/material/ToggleButton'
import PropTypes from 'prop-types'

const StyledToggleButton = styled(MuiToggleButton)({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: '#b1b1b1 !important'
  },
  '&.MuiToggleButton-root': {
    color: 'white',
    backgroundColor: '#76B72A'
  }
})

const ToggleButton = ({ content, value, selected, onChange }) => {
  return (
    <StyledToggleButton
      sx={{
        textTransform: 'none',
        backgroundColor: '#76B72A',
        height: '55px',
        ml: '5px',
        mt: '12px'
      }}
      value={value}
      selected={selected}
      onChange={onChange}
    >
      {content}
    </StyledToggleButton>
  )
}

ToggleButton.propTypes = {
  content: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
}

export default ToggleButton
