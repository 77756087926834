import { enqueueSnackbar } from 'notistack'
import { overviewGetAllPermissionsByGuard, overviewGetRole } from '~/api'
import { useCustomQuery as useQuery } from '~/hooks/useQuery'
import { parseApiErrorMessage } from '~/utils/helpers'
import roleApi from '~/api/roleApi'

export const useRoleDetailQuery = params => {
  return useQuery(
    ['roles_detail', params.id],
    async () => {
      const { data } = await overviewGetRole(params.id)
      return data?.data
    },
    {
      onError: error => {
        enqueueSnackbar({
          message: parseApiErrorMessage(error),
          variant: 'error'
        })
      },
      retry: false
    }
  )
}

export const useGetAllPermissionsQuery = guardName => {
  return useQuery(
    ['roles_detail', 'get_all_permissions', guardName],
    async () => {
      const { data } = await overviewGetAllPermissionsByGuard(guardName)
      return data?.data
    },
    {
      onError: error => {
        enqueueSnackbar({
          message: parseApiErrorMessage(error),
          variant: 'error'
        })
      },
      retry: false
    }
  )
}

export const useGetAllUsersWithRoleQuery = ({ id, ...params }) => {
  return useQuery(
    ['users_of_role', id, params],
    async () => {
      const { data } = await roleApi.getUsersWithRole(id, params)
      return { data: data.data, total: data.meta.total }
    },
    {
      onSuccess: () => {},
      onError: () => {},
      retry: false
    }
  )
}
