import { yupResolver } from '@hookform/resolvers/yup'
import AddIcon from '@mui/icons-material/Add'
import { LoadingButton } from '@mui/lab'
import {
  Autocomplete,
  Breadcrumbs,
  Divider,
  Grid,
  Paper,
  Stack,
  Table,
  TableContainer,
  TextField,
  Typography
} from '@mui/material'
import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import ListToolbar from '~/components/ListToolbar'
import CustomModal from '~/components/Modal/CustomModal'
import PaginationTable from '~/components/PaginationTable'
import TableFilters from '~/components/Table/TableFilters'
import TableHeader from '~/components/TableHeader'
import useLanguage from '~/hooks/useLanguage'
import useTable from '~/hooks/useTable'
import UsersTableBody from './Component/UsersTableBody'
import { useAddExtraUserToTenantMutation } from './mutate'
import { useActiveTenantsQuery, useUsersQuery } from './query'
import { UserHeadCells } from './UserHeadCells'

const localization = 'pages.adminOverview.users.'
const schema = yup
  .object({
    tenant_id: yup.string().required(),
    email: yup.string().email().required(),
    language: yup.string().required()
  })
  .required()

const Users = () => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState(null)
  const { languages, language, setLanguage } = useLanguage()

  const { data: tenants, isSuccess } = useActiveTenantsQuery()

  const { mutate } = useAddExtraUserToTenantMutation()

  const handleOnClose = () => {
    setOpen(false)
  }

  const onSubmit = async payload => {
    mutate(payload, {
      onSuccess: () => {
        setOpen(false)
        reset()
        setSelected(null)
      }
    })
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      tenant_id: '',
      email: '',
      language: 'en'
    }
  })

  const {
    data,
    isLoading,
    order,
    page,
    orderBy,
    search,
    searchHandler,
    sortHandler,
    changePageHandler
  } = useTable({
    query: useUsersQuery,
    initialStates: {
      order: 'desc',
      page: 1,
      orderBy: 'created_at'
    }
  })

  const toolBarButtons = useMemo(() => {
    return [
      {
        button: 'addUser',
        icon: <AddIcon />,
        onClick: e => {
          setOpen(true)
        }
      }
    ]
  }, [])

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb'>
        <Typography> {t('adminMenu.administrator.schoolUsers.schoolUsers')}</Typography>
      </Breadcrumbs>
      <Divider />
      <Paper elevation={1}>
        <ListToolbar toolBarButton={toolBarButtons} />
        <TableFilters
          {...{
            search,
            searchHandler
          }}
          onEnterSearch={searchHandler}
          showDatePicker={true}
          searchPlaceHolder={t('general.searchFor') + t('general.users')}
          page='school-users-overview'
        />
        <Divider />

        <TableContainer>
          <Table>
            <TableHeader
              headCells={UserHeadCells}
              onRequestSort={sortHandler}
              order={order}
              orderBy={orderBy}
              localization={localization}
            />
            <UsersTableBody isLoaing={isLoading} users={data} />
          </Table>
        </TableContainer>
        <PaginationTable
          data={data}
          page={page}
          size='medium'
          handleChangePage={changePageHandler}
        />
      </Paper>

      <CustomModal open={open} onClose={handleOnClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid item mb={5}>
            <Typography>{t(`${localization}add`)}</Typography>
          </Grid>

          <Grid item mb={2}>
            <TextField
              fullWidth
              variant='outlined'
              label={t(`${localization}email`)}
              onChange={() => {}}
              error={!!errors.email}
              {...register('email')}
              helperText={errors.email?.message}
            />
          </Grid>
          <Grid item mb={2}>
            {isSuccess && (
              <Autocomplete
                id='tenant'
                name='tenant'
                value={selected}
                options={tenants?.map(option => ({
                  label: option.default_domain_name,
                  id: option.id
                }))}
                getOptionLabel={option => option.label}
                onChange={(e, value) => {
                  setSelected(value)
                  setValue('tenant_id', value.id)
                }}
                sx={{ mt: 2 }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={t(`${localization}selectTenant`)}
                    onChange={() => {}}
                    error={!!errors.tenant_id}
                    helperText={errors.tenant_id?.message}
                  />
                )}
              />
            )}
          </Grid>
          <Grid item mb={2}>
            <Autocomplete
              id='language'
              name='language'
              freeSolo
              value={language}
              options={languages.map(option => option)}
              onChange={(e, value) => {
                setValue('language', value.id)
                setLanguage(value)
              }}
              sx={{ mt: 1 }}
              renderInput={params => <TextField {...params} label={t(`${localization}language`)} />}
            />
          </Grid>
          <Stack sx={{ mt: 2 }} direction='row-reverse' gap={1}>
            <LoadingButton variant='contained' type='submit'>
              {t(`${localization}save`)}
            </LoadingButton>
            <LoadingButton variant='contained' type='submit' onClick={handleOnClose}>
              {t(`${localization}cancel`)}
            </LoadingButton>
          </Stack>
        </form>
      </CustomModal>
    </>
  )
}

export default Users
