import { Check, Error, ExpandLess, ExpandMore, Info } from '@mui/icons-material'
import {
  Box,
  Collapse,
  Divider,
  Grid,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography
} from '@mui/material'
import PropTypes from 'prop-types'
import { useState } from 'react'

const HealthCheckBox = ({ icon, title, data, extraInfo, children }) => {
  const [openBox, setOpenBox] = useState(true)
  const waitingForData = data == null
  const isSuccess = data?.is_success
  const errorCode = data?.error_code
  const code = data?.code

  const messages = waitingForData ? ['Waiting for data...'] : data?.message?.split(';')

  let statusIconColor = 'red'
  let StatusIconComponent = Error

  if (waitingForData) {
    statusIconColor = 'grey'
    StatusIconComponent = Info
  } else if (isSuccess) {
    statusIconColor = '#76B72A'
    StatusIconComponent = Check
  } else if (code === 'CHECK_AUTOPILOT_CNAME' && errorCode !== 'CHECK_MISSING_AUTOPILOT_CNAME') {
    statusIconColor = 'orange'
  }

  const statusIcon = <StatusIconComponent sx={{ color: statusIconColor }} />

  return (
    <>
      <Divider style={{ marginTop: 20 }} />
      <Paper sx={{ padding: 4 }}>
        <ListItemButton onClick={() => setOpenBox(i => !i)}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={title} />
          {isSuccess !== undefined && statusIcon}
          {openBox ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openBox} timeout='auto' unmountOnExit>
          <Grid container direction='row' paddingLeft={5}>
            {messages && messages.length > 0 ? (
              <>
                <Grid container item xs={extraInfo ? 6 : 12} mb={2}>
                  <Box sx={{ display: 'flex' }}>
                    <Box mb={0.5}>{isSuccess !== undefined && statusIcon}</Box>
                    <Typography marginLeft={4}>
                      {messages.map(item => {
                        return (
                          <Typography key={item} style={{ width: '100%' }}>
                            {item}
                          </Typography>
                        )
                      })}
                    </Typography>
                  </Box>
                </Grid>
                {extraInfo && (
                  <Grid container item xs={6}>
                    {extraInfo}
                  </Grid>
                )}
              </>
            ) : (
              <Grid container item xs={12}>
                {extraInfo}
              </Grid>
            )}
          </Grid>

          {children}
        </Collapse>
      </Paper>
    </>
  )
}

HealthCheckBox.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  data: PropTypes.object,
  extraInfo: PropTypes.node,
  children: PropTypes.node
}

export default HealthCheckBox
