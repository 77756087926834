import { yupResolver } from '@hookform/resolvers/yup'
import ErrorIcon from '@mui/icons-material/Error'
import SettingsIcon from '@mui/icons-material/Settings'
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { CancelButton } from '~/components/Button/CancelButton'
import { LoadingButton } from '~/components/Button/LoadingButton'
import CustomModal from '~/components/Modal/CustomModal'
import {
  useSwitchDeviceNameFlowMutation,
  useUpdateDeviceNameConfig
} from '~/pages/HealthCheck/mutation'
import { isOverviewPage } from '~/utils/helpers'

const localization = 'pages.organisation'

const DeviceNameConfig = ({ organisationDetail, ref }) => {
  const { t } = useTranslation('translation', { keyPrefix: localization })
  const [open, setOpen] = useState(
    organisationDetail.enable_set_device_name &&
      (organisationDetail.device_prefix_name === '' ||
        organisationDetail.device_prefix_name === null)
  )

  const { mutate: toggleSetDeviceName, isLoading: isLoadingSetDeviceName } =
    useSwitchDeviceNameFlowMutation(organisationDetail.id)
  const { mutate: updateDevicePrefix } = useUpdateDeviceNameConfig(organisationDetail.id)

  const schema = useMemo(() => {
    return yup.object({
      device_padding_number: yup
        .number()
        .typeError(t('sequenceNumber.type'))
        .required(t('sequenceNumber.required'))
        .integer(t('sequenceNumber.integer'))
        .min(0, t('sequenceNumber.min'))
        .max(5, t('sequenceNumber.max')),
      device_prefix_name: yup
        .string()
        .required(t('prefixName.required'))
        .matches(/^[A-Za-z0-9]+(-[A-Za-z0-9]+)*-?$/, t('prefixName.matches'))
        .test('prefix-length', t('prefixName.max'), function (value) {
          const { device_suffix_sequence, device_padding_number } = this.parent
          const padding = device_padding_number || 0
          const suffix = device_suffix_sequence ? device_suffix_sequence.padStart(padding, '0') : ''
          const fullName = `${value}-${suffix}`
          return fullName.length <= 15
        }),
      device_suffix_sequence: yup
        .string()
        .required(t('suffixSequence.required'))
        .test('is-numeric', t('suffixSequence.numeric'), value => /^\d+$/.test(value))
        .test('matches-padding', t('suffixSequence.padding'), function (value) {
          const padding = this.parent.device_padding_number || 0
          return value.length === padding
        })
        .test('greater-or-equal', t('suffixSequence.greaterOrEqual'), function (value) {
          const currentSuffixSequence = this.options.context?.currentSuffix || 0
          return parseInt(value, 10) >= parseInt(currentSuffixSequence, 10)
        })
        .test('suffix-length', t('suffixSequence.combinedMax'), function (value) {
          const { device_prefix_name, device_padding_number } = this.parent
          const padding = device_padding_number || 0
          const paddedSuffix = value.padStart(padding, '0')
          const fullName = `${device_prefix_name}-${paddedSuffix}`
          return fullName.length <= 15
        })
    })
  }, [t])

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema, { context: organisationDetail })
  })

  const onSubmit = async values => {
    updateDevicePrefix(values, {
      onSuccess: () => handleClose()
    })
  }

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const prefixName = watch('device_prefix_name', organisationDetail.device_prefix_name || '')
  const sequenceNumber = watch(
    'device_padding_number',
    organisationDetail.device_padding_number || 0
  )

  const renderExampleMessage = useMemo(() => {
    if (!prefixName || sequenceNumber === null) return ''

    const sequenceLength = parseInt(sequenceNumber, 10)

    // Generate a test name to validate the total length
    const deviceName =
      sequenceLength === 0 ? prefixName : `${prefixName}-${'0'.repeat(sequenceLength)}` //

    if (deviceName.length > 15)
      return [`${t('deviceName.prefix')} ${deviceName} ${t('deviceName.tooLong')}`]

    if (sequenceLength === 0) {
      return `${t('example')}: ${deviceName}`
    }

    const paddedNumbers = [1, 10, 100, 1000, 10000]
      .filter(num => num < Math.pow(10, sequenceLength))
      .map(num => String(num).padStart(sequenceLength, '0'))

    const examples = paddedNumbers.map(num => `${prefixName}-${num}`).join(', ')
    if (examples.length > 0) {
      return `${t('example')}: ${examples}`
    }

    return t('prefixNameGuide')
  }, [prefixName, sequenceNumber, t])

  const renderToggle = useMemo(() => {
    if (!isOverviewPage()) {
      return (
        <Grid item xs={1}>
          <Typography sx={{ marginTop: '6px' }}>
            {organisationDetail?.enable_set_device_name ? 'true' : 'false'}:
          </Typography>
        </Grid>
      )
    }

    return (
      <>
        <Grid item xs={1}>
          <Switch
            checked={organisationDetail?.enable_set_device_name}
            onChange={toggleSetDeviceName}
            inputProps={{ 'aria-label': 'controlled' }}
            disabled={isLoadingSetDeviceName}
          />
        </Grid>
        {organisationDetail?.enable_set_device_name && (
          <Grid item xs={1}>
            <Box sx={{ mt: 1 }}>
              <SettingsIcon onClick={() => setOpen(true)}></SettingsIcon>
            </Box>
          </Grid>
        )}
      </>
    )
  }, [isLoadingSetDeviceName, organisationDetail?.enable_set_device_name, toggleSetDeviceName])

  return (
    <>
      <Grid container alignItems='center' spacing={1.25}>
        <Grid item xs={4}>
          <Typography sx={{ marginTop: '6px' }}>{t(`setDeviceName`)}:</Typography>
        </Grid>
        {renderToggle}
        <Grid item xs={6}></Grid>

        {!!organisationDetail?.enable_set_device_name && (
          <>
            <Grid item xs={4}>
              <Typography sx={{ marginTop: '6px' }}>{t('prefixName.name')}:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography sx={{ marginTop: '6px' }}>
                {organisationDetail.device_prefix_name}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography sx={{ marginTop: '6px' }}>{t('sequenceNumber.name')}:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography sx={{ marginTop: '6px' }}>
                {organisationDetail.device_padding_number}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={{ marginTop: '6px' }}>{t('deviceName.currentName')}:</Typography>
            </Grid>

            <Grid item xs={8}>
              <Typography sx={{ marginTop: '6px' }}>
                {organisationDetail.device_suffix_sequence}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>

      <CustomModal
        open={open}
        onClose={() => {}}
        style={{
          width: 800
        }}
      >
        <form ref={ref} onSubmit={handleSubmit(onSubmit)}>
          <Typography variant='h6' sx={{ mt: 1, mb: 2, fontWeight: 'bold' }} gutterBottom>
            {t('deviceNameConfig')}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={5}>
              <Typography sx={{ mt: 2 }}>{t('prefixName.name')}:</Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <TextField
                {...register('device_prefix_name')}
                variant='outlined'
                fullWidth
                defaultValue={organisationDetail.device_prefix_name}
                error={!!errors.device_prefix_name}
                helperText={errors.device_prefix_name?.message}
                inputProps={{
                  maxLength: 15
                }}
              />
            </Grid>

            <Grid item xs={12} sm={5}>
              <Stack direction='row' gap={1}>
                <Typography sx={{ mt: 2 }}>{t('sequenceNumber.name')}</Typography>
                <Tooltip
                  sx={{ mt: 1 }}
                  title={
                    <Box>
                      <Typography variant='subtitle2' color='error' gutterBottom>
                        {t('caution.title')}
                      </Typography>
                      <Typography variant='body2' gutterBottom>
                        {t('caution.body')}
                      </Typography>
                      <Typography variant='body2' fontStyle='italic'>
                        <strong>{t('caution.tip.text')}</strong> {t('caution.tip.body')}
                      </Typography>
                    </Box>
                  }
                  placement='right'
                  arrow
                >
                  <IconButton>
                    <ErrorIcon color='warning' />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={7}>
              <TextField
                {...register('device_padding_number')}
                variant='outlined'
                defaultValue={organisationDetail.device_padding_number}
                fullWidth
                error={!!errors.device_padding_number}
                helperText={errors.device_padding_number?.message}
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  maxLength: 1
                }}
              />
            </Grid>

            <Grid item xs={12} sm={5}>
              <Typography sx={{ mt: 2 }}>{t('deviceName.currentName')}</Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <TextField
                {...register('device_suffix_sequence')}
                variant='outlined'
                fullWidth
                defaultValue={organisationDetail.device_suffix_sequence}
                error={!!errors.device_suffix_sequence}
                helperText={errors.device_suffix_sequence?.message}
                inputProps={{
                  maxLength: 15
                }}
              />
            </Grid>

            <Grid item xs={12} mt={2} mb={2}>
              <Typography variant='subtitle2'>{renderExampleMessage}</Typography>
            </Grid>
          </Grid>

          <Divider />
          <Stack sx={{ mt: 2 }} direction='row-reverse' gap={1}>
            <LoadingButton variant='contained' type='submit'>
              {t('save')}
            </LoadingButton>
            <CancelButton onClick={handleClose} />
          </Stack>
        </form>
      </CustomModal>
    </>
  )
}

DeviceNameConfig.propTypes = {
  organisationDetail: PropTypes.object,
  ref: PropTypes.object
}

DeviceNameConfig.defaultProps = {
  organisationDetail: () => {}
}

export default DeviceNameConfig
