import { Chip, Link, TableBody, TableCell, TableRow } from '@mui/material'
import PropTypes from 'prop-types'
import DataNotFound from '~/components/DataNotFound'
import DateTimeLocale from '~/components/DateTimeLocale'
import TableLoading from '~/components/Table/TableLoading'
import { useAdminPermission } from '~/hooks/useAdminPermission'
import { getUserStatusColor } from '~/utils/helpers'
import { UserHeadCells } from '../UserHeadCells'

const OverviewUsersTableBody = ({ isLoading, users }) => {
  const { overviewUser } = useAdminPermission()
  if (isLoading) {
    return <TableLoading colSpan={UserHeadCells.length} />
  }

  const renderIdCol = row =>
    overviewUser.administrator.users.canViewAdminDetail ? (
      <Link href={`/overview/super-users/${row.id}`} sx={{ ml: 3 }}>
        {row.id}
      </Link>
    ) : (
      row.id
    )

  return (
    <TableBody>
      {users?.data?.length === 0 && <DataNotFound colSpan={UserHeadCells.length} />}
      {users?.data?.length > 0 &&
        users?.data?.map(row => {
          const chipcolor = getUserStatusColor(row?.status)
          return (
            <TableRow key={row.id}>
              <TableCell>{renderIdCol(row)}</TableCell>
              <TableCell>
                {row?.first_name} {row?.last_name}
              </TableCell>
              <TableCell>{row?.email}</TableCell>
              <TableCell>{row?.role}</TableCell>
              <TableCell>
                {row?.status ? (
                  <Chip
                    label={row?.status ?? 'active'}
                    color={row?.status ? chipcolor : 'success'}
                    size='small'
                  />
                ) : (
                  'N/A'
                )}
              </TableCell>
              <TableCell>
                <DateTimeLocale datetime={row?.created_at} />
              </TableCell>
            </TableRow>
          )
        })}
    </TableBody>
  )
}

OverviewUsersTableBody.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  users: PropTypes.shape({
    data: PropTypes.array.isRequired
  }).isRequired
}

export default OverviewUsersTableBody
