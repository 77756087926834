import { Box, Card, Grid, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import CustomCardHeader from '~/components/CustomCardHeader'
import UsersTable from '~/pages/Admin/Administrator/Users/Component/UsersTable'
import { useGetAllUsersWithRoleQuery } from '../query'
import { useParams } from 'react-router-dom'
import { ADMIN_ACCOUNT, SERVICE_ACCOUNT, USER_ACCOUNT } from '~/constants/constants'
import OverviewUsersTable from '~/pages/Admin/Administrator/Admins/Component/OverviewUsersTable'

const RoleUser = ({ guardName }) => {
  const { id } = useParams()
  return (
    <Grid container spacing={2}>
      <Stack direction='column' spacing={3} sx={{ width: '100%', paddingBottom: 0 }}>
        <Card>
          <CustomCardHeader
            title={''}
            action={
              <Box
                direction='row'
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 0.5,
                  maxHeight: '50px'
                }}
              />
            }
          />
          {[ADMIN_ACCOUNT, SERVICE_ACCOUNT].includes(guardName) && (
            <OverviewUsersTable query={useGetAllUsersWithRoleQuery} queryAttributes={{ id }} />
          )}
          {guardName === USER_ACCOUNT && (
            <UsersTable query={useGetAllUsersWithRoleQuery} queryAttributes={{ id }} />
          )}
        </Card>
      </Stack>
    </Grid>
  )
}

RoleUser.propTypes = {
  guardName: PropTypes.string
}

RoleUser.defaultProps = {
  guardName: USER_ACCOUNT
}

export default RoleUser
