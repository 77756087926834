import { useTranslation } from 'react-i18next'
import UsersTable from '../Component/UsersTable'
import { useDisableUsersQuery } from '../query'

const DisableUsers = () => {
  const { t } = useTranslation()
  return (
    <UsersTable
      query={useDisableUsersQuery}
      title={t('adminMenu.administrator.schoolUsers.disable')}
    />
  )
}

export default DisableUsers
