import { Group } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, CircularProgress, Grid, Stack, Switch, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import CompanySchoolFormSelector from '~/components/CompanySchoolFormSelector'
import CustomModal from '~/components/Modal/CustomModal'
import TenantIcon from '~/components/layout/IconComponent'
import { useGetOrgsQuey } from '~/pages/Admin/Administrator/InviteAzureTenants/query'
import { useUpdateSchoolTenantIdMutation } from '~/pages/HealthCheck/mutation'
import { isOverviewPage } from '~/utils/helpers'
import HealthCheckBox from './HealthCheckBox'
import OrganisationDetails from '../Organisations/Organisations/OrganisationOverViewTable/OrganisationAzureDetails'
import { CancelButton } from '~/components/Button/CancelButton'
import OrganisationGoogleDetails from '../Organisations/Organisations/OrganisationOverViewTable/OrganisationGoogleDetails'

const SchoolSection = ({ tenant }) => {
  const { t } = useTranslation()

  const schema = yup
    .object({
      school_id: yup
        .string()
        .nullable()
        .test({
          name: 'schoolEntityCheck',
          message: t('validation.schoolAndEntityReuiredTogether'),
          test: function (value) {
            const { entity_id } = this.parent
            if ((value && !entity_id) || (!value && entity_id)) {
              return false
            }
            return true
          }
        }),
      entity_id: yup
        .string()
        .nullable()
        .test({
          name: 'entitySchoolCheck',
          message: t('validation.schoolAndEntityReuiredTogether'),
          test: function (value) {
            const { school_id } = this.parent
            if ((value && !school_id) || (!value && school_id)) {
              return false
            }
            return true
          }
        })
    })
    .required()

  // States
  const [open, setOpen] = useState(false)
  const [openSchool, setOpenSchool] = useState(false)
  const [selectedSchool, setSelectedSchool] = useState(null)
  const [toggle, setToggle] = useState(tenant?.schools?.length > 0)

  // Queries
  const { data: orgData, isLoading } = useGetOrgsQuey(
    toggle
      ? {
          filter: 1,
          tenant_id: tenant.id
        }
      : {
          tenant_id: tenant.id
        },
    isOverviewPage()
  )

  // Mutations
  const { mutate: updateSchoolTenantId } = useUpdateSchoolTenantIdMutation()

  // Handlers
  const handleCloseSchool = () => {
    setOpen(false)
  }

  const mostSelected = useMemo(() => {
    const selected = tenant.schools || []
    if (selected.length > 0) {
      const entityCount = selected.reduce((count, school) => {
        count[school.entity_id] = (count[school.entity_id] || 0) + 1
        return count
      }, {})

      return Number(
        Object.keys(entityCount).reduce((a, b) => (entityCount[a] > entityCount[b] ? a : b))
      )
    }
    return null
  }, [tenant.schools])

  const defaultValues = useMemo(() => {
    return {
      school_id: '',
      entity_id: ''
    }
  }, [])

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  })

  const onSubmit = async values => {
    updateSchoolTenantId(
      {
        ...values,
        id: tenant.id
      },
      {
        onSuccess: () => {
          handleCloseSchool()
        }
      }
    )
  }

  const handleClickSchool = school => {
    setSelectedSchool(school)
    setOpenSchool(true)
  }

  const handleCloseSchoo = e => setOpenSchool(false)

  useEffect(() => {
    if (tenant.schools && selectedSchool) {
      const school = tenant.schools.find(v => v.id === selectedSchool.id)
      if (!school) {
        setSelectedSchool(null)
      }
    }
  }, [selectedSchool, tenant.schools])

  if (!isOverviewPage()) {
    return null
  }

  return (
    <>
      <HealthCheckBox icon={<Group />} title={t('pages.organisation.organisation')} data={[]}>
        <Grid container direction='row' paddingLeft={5}>
          <Grid container item xs={12}>
            <Box sx={{ display: 'flex' }}>
              <Typography marginLeft={4}>
                {tenant.schools.map(school => {
                  return (
                    <Typography
                      onClick={e => handleClickSchool(school)}
                      key={school.id}
                      style={{
                        width: '100%',
                        cursor: 'pointer'
                      }}
                    >
                      {school.name} -- ({school.external_id})
                    </Typography>
                  )
                })}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <LoadingButton onClick={() => setOpen(true)} sx={{ mt: 1, mr: 1 }} variant='contained'>
          {t('button.add')}
        </LoadingButton>
      </HealthCheckBox>

      {selectedSchool && tenant.is_azure && (
        <CustomModal
          open={openSchool}
          onClose={handleCloseSchoo}
          style={{
            width: 800
          }}
        >
          <OrganisationDetails organisationId={selectedSchool.id} />

          <Stack sx={{ mt: 2 }} direction='row-reverse' gap={1}>
            <CancelButton onClick={handleCloseSchoo} />
          </Stack>
        </CustomModal>
      )}

      {selectedSchool && tenant.is_google && (
        <CustomModal
          open={openSchool}
          onClose={handleCloseSchoo}
          style={{
            width: 800
          }}
        >
          <OrganisationGoogleDetails organisationId={selectedSchool.id} />

          <Stack sx={{ mt: 2 }} direction='row-reverse' gap={1}>
            <CancelButton onClick={handleCloseSchoo} />
          </Stack>
        </CustomModal>
      )}

      <CustomModal
        title={t('pages.tenant.addSchoolToTenant')}
        open={open}
        onClose={handleCloseSchool}
        style={{
          width: 800
        }}
      >
        <Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography variant='h6' sx={{ mt: 1, fontWeight: 'bold' }} gutterBottom>
              {t('pages.tenant.addSchoolToTenant')}
            </Typography>
            <CompanySchoolFormSelector
              errors={errors}
              control={control}
              orgData={orgData}
              setValue={setValue}
              mostUsedEntityId={mostSelected}
              schoolDisableOption={option => {
                return Boolean(option.tenant_id)
              }}
              schoolRenderOption={(props, option) => (
                <li {...props}>
                  {isLoading ? (
                    <CircularProgress />
                  ) : (
                    <Box display='flex' alignItems='center' gap='8px'>
                      <TenantIcon item={option} />
                      {option.tenant_id ? (
                        <>
                          <del>{option.label}</del>({t(`pages.tenant.inOtherTenant`)})
                        </>
                      ) : (
                        option.label
                      )}
                    </Box>
                  )}
                </li>
              )}
            />
            <Box sx={{ mt: 2 }} direction='row-reverse' gap={1}>
              <Switch
                checked={toggle}
                onChange={e => {
                  setToggle(e.target.checked)
                }}
                disabled={isLoading}
              />
              <Typography>{t('pages.tenant.filterSchool')}</Typography>
            </Box>
            <Stack sx={{ mt: 2 }} direction='row-reverse' gap={1}>
              <LoadingButton variant='contained' type='submit'>
                {t('pages.tenant.save')}
              </LoadingButton>
              <CancelButton onClick={handleCloseSchool} />
            </Stack>
          </form>
        </Box>
      </CustomModal>
    </>
  )
}

SchoolSection.propTypes = {
  tenant: PropTypes.shape({
    id: PropTypes.number.isRequired,
    schools: PropTypes.array,
    is_azure: PropTypes.bool,
    is_google: PropTypes.bool
  }).isRequired
}

export default SchoolSection
