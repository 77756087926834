import ic_flag_en from '~/assets/flags/ic_flag_en.svg'
import ic_flag_nl from '~/assets/flags/ic_flag_nl.svg'
import ic_flag_fr from '~/assets/flags/ic_flag_fr.svg'
import ic_flag_de from '~/assets/flags/ic_flag_de.svg'

export const KEY_AUTH_TOKEN = 'AUTH_TOKEN'

export const DEFAULT_LANGUAGE = 'en'

export const PER_PAGE = 15

export const SUPER_ADMIN = 'super_admin'

export const MULTI_LANGUAGES = [
  {
    locale: 'en',
    language: 'English',
    icon: ic_flag_en
  },
  {
    locale: 'nl',
    language: 'Netherlands',
    icon: ic_flag_nl
  },
  {
    locale: 'fr',
    language: 'France',
    icon: ic_flag_fr
  },
  {
    locale: 'de',
    language: 'Germany',
    icon: ic_flag_de
  }
]

export const APPLICATION_REGISTRATION_KEY = 'application_registration'
export const APPLICATION_REGISTRATION_STATUS_READY = 'ready'
export const SAVED_URL = 'SAVED_URL'

export const KEY_ADMIN_AUTH_TOKEN = 'ADMIN_AUTH_TOKEN'
export const AZURE = 'azure'
export const GOOGLE = 'google'
export const TENANT_TYPES = [AZURE, GOOGLE]

export const AZURE_USER = 'azure'
export const GOOGLE_USER = 'google'

export const REGISTRATION_STATE = 'registration_state'
export const HEALTH_CHECK_PERMISSION = 'health_check_permissions'

export const USER_ACCOUNT = 'user_account'
export const ADMIN_ACCOUNT = 'admin_account'
export const SERVICE_ACCOUNT = 'service_account'
