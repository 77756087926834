// eslint-disable-next-line import/no-anonymous-default-export
export default {
  translation: {
    general: {
      english: 'Engels',
      dutch: 'Nederlands',
      searchFor: 'Zoeken naar ',
      serials_or_State: 'serienummers of staat',
      serials_or_importStatus: 'serienummers of status',
      serials_or_AadDeviceId: 'serials or AadDeviceId',
      serials_or_preProvisioningId: 'serials or Pre Provisioning ID',
      name_or_domain_name: 'Name or Domain Name',
      schoolName: 'schoolnaam',
      dashboard: 'Dashboard',
      yourAccountAreAllSet: 'Je account is klaar!',
      hasIntuneLicenceValid:
        'De verbinding met de tenant is verbroken, geef de app opnieuw toestemming',
      grantAppPermission: 'Ken rechten toe aan applicatie.',
      tenantOverview: 'Overzicht Tenant',
      registerSessionOverview: 'Overzicht apparaatsessies registreren',
      registrationDetail: 'Registratie detail',
      healthCheck: 'Gezondheids controle',
      ggHealthCheck: 'Google Domein gezondheidscontrole',
      syncDevice: 'Apparaat Synchroniseren',
      syncingDevice: 'Apparaten synchroniseren',
      overview: 'Overzicht',
      inviteAzureTenant: 'Nodig Azure-tenant uit',
      inviteGoogleDomain: 'Nodig Google Domain uit',
      users: 'Users',
      uploadCSV: 'Upload CSV of TXT bestand',
      bulkDeleteDevice: 'Bulk verwijderen van apparaten',
      missingPermission:
        'Het lijkt erop dat u niet de nodige rechten heeft om deze pagina te bekijken!',
      checkZteToken: 'Klik op deze knop om de geldigheid van ZTE Token te controleren',
      successfullUpdate: 'Succesvol bijgewerkt.',
      searchRange: 'Selecteer datumbereik',
      clearFilter: 'Filter wissen',
      noOption: 'Geen opties'
    },
    adminMenu: {
      dashboard: 'Dashboard',
      tenants: 'Tenants',
      devices: 'Apparaten',
      devices_register_session: 'Apparaat Registratiesessies',
      administrator: {
        administrator: 'Beheerder',
        inviteAzureTenants: 'Nodig Azure Tenant uit',
        inviteGooleDomain: 'Nodig Google Domein uit',
        users: 'Gebruikers',
        schoolUsers: {
          registrations: 'Registraties',
          schoolUsers: 'Schoolgebruikers',
          disable: 'Schoolgebruikers uitschakelen'
        },
        admins: {
          admins: 'Beheerders',
          disable: 'Beheerders uitschakelen'
        },
        companies: 'Bedrijven',
        roles: 'Rollen',
        settings: 'Instellingen'
      }
    },
    userMenu: {
      dashboard: 'Dashboard',
      dashboardOverview: 'Dashboard Overzicht',
      healthCheck: 'Gezondheidscontrole',
      devices: 'Apparaten',
      organisations: 'Organisaties',
      organisationsOverview: 'Overzicht',
      groupTags: 'Groep Tags',
      preToken: 'Preprovisioning Token',
      logs: 'Logboeken'
    },
    login: {
      loginButton: 'Inloggen',
      welcomeTitle: 'Welkom bij easy4Enroll!',
      privacyStatement: 'Privacyverklaring inschrijven',
      termOfService: 'Servicevoorwaarden',
      faq: 'FAQ'
    },
    breadCrumb: {
      Home: 'Home',
      Dashboard: 'Dashboard',
      Devices: 'Devices'
    },
    button: {
      create: 'Create',
      register: 'Register',
      edit: 'Edit',
      close: 'Close',
      SignInWithMicrosoft: 'Meld u aan bij Microsoft',
      SignInWithGoogle: 'Log in met Google',
      registerEasy4Enroll: 'Register easy4Enroll app',
      grantAdminConsent: 'Verleen toepassingsmachtigingen',
      bulkDeregisBtn: 'Bulk uitschrijven',
      deRegister: 'De-register',
      inRepair: 'In-repair',
      isRepaired: 'Is-Repaired',
      captureDevices: '',
      invite: 'Uitnodiging',
      delete: 'Verwijderen',
      disable: 'Uitzetten',
      enable: 'Inschakelen',
      add: 'Toevoegen',
      setup: 'Opzetten',
      upload: 'Upload',
      bulkDeleteByCSV: 'Bulk verwijderen door CSV',
      cancel: 'Annuleren',
      addUser: 'Voeg gebruiker toe',
      addCompany: 'Voeg bedrijf toe',
      addGoogleDomain: 'Google-domein toevoegen'
    },
    profileSetting: {
      signOut: 'Uitloggen',
      openSetting: 'Open instelling',
      settingManagement: `Instellingen & beheer`
    },
    message: {
      authenticationFail: 'Verificatie mislukt',
      noData: 'Geen gegevens',
      approved: 'Dit account is goedgekeurd.',
      rejected: 'Dit account is afgewezen.',
      existedUser: 'Bestaande gebruiker:',
      deleteUser: 'Deze gebruiker is succesvol verwijderd',
      disableAccount: 'Deze gebruiker is uitgeschakeld',
      importSuccess: 'Importeren is succesvol',
      importFailed: 'Importeren is mislukt'
    },
    pages: {
      device: {
        serialNumber: 'Serienummer',
        state: 'Staat',
        aadDeviceId: 'AAD Apparaat ID',
        vendor: 'Leverancier',
        model: 'Model',
        osSystem: 'Besturingssysteem',
        osVersion: 'OS versie',
        groupTag: 'Groepslabel',
        profile: 'Inschrijvingsprofiel',
        action: 'Actie',
        googleDeviceCreated: 'Google-apparaat gemaakt',
        overview: {
          pageTitle: 'Lijst met apparaten'
        },
        create: {
          pageTitle: 'Registreer een apparaat',
          serialNumber: 'Serienummer',
          productKey: 'Product sleutel',
          hardwareHash: 'Hardware-hash',
          hardwareModel: 'Hardware-model',
          domain: 'Domein',
          customerId: 'Klanten ID',
          attestedDeviceId: 'Geattesteerde apparaat-ID',
          externalID: 'Externe ID'
        },
        detail: {
          deviceOverview: 'Apparaat overzicht',
          deviceInformation: 'Apparaat informatie',
          deviceSessionInformation: 'Apparaat sessie informatie'
        },
        status: 'Status',
        error_name: 'Foutbeschrijving',
        register_status: 'Register status',
        preProvisioningToken: 'Pre-provisioning-token',
        preProvisionedDeviceId: 'Vooraf ingerichte apparaat-ID',
        brandCode: 'Merkcode',
        putDeviceToRepair: 'Wilt u dit apparaat ter reparatie aanbieden',
        putDeviceToIsRepaired:
          'Zorg ervoor dat u een image van het apparaat maakt wanneer het apparaat is gerepareerd',
        azureTenant: 'Azure Tenant',
        googleDomain: 'Google Domain',
        deleteDevices: 'Wilt u deze apparaten afmelden?',
        deleteDevice: 'Wilt u dit apparaat afmelden:',
        registerSession: {
          ipAddress: 'IP adres',
          complete: 'Voltooid',
          serialNumber: 'Serienummer',
          state: 'Staat',
          successCode: 'Succes Code',
          createdAt: 'Gemaakt Bij',
          updatedAt: 'Bijgewerkt Op',
          message: 'Bericht',
          completed: 'Voltooid',
          organisation: 'Organisatie'
        },
        downloadExample: 'Download voorbeeldbestanden ',
        here: 'hier',
        toSeeTheFormat: ' om het acceptabele formaat te zien.',
        cancel: 'Annuleren',
        download: 'Resultaten downloaden',
        delete: 'Verwijderen',
        confirm: 'Bevestigen',
        type: 'Typ JA om alle geïmporteerde serienummers te verwijderen',
        totalRecognizedSerials: 'Totaal herkende serienummers: ',
        totalNotManaged: 'Totaal serienummers niet beheerd door TRC: ',
        totalNotRecognizedSerials: 'Totaal niet herkende serienummers: ',
        deleteAllNotManagedi: 'Verwijder ook alle serienummers die niet door TRC worden beheerd',
        chooseFile: 'Kies bestand',
        noFileChosen: 'Geen bestand gekozen'
      },
      organisation: {
        Id: 'ID',
        name: 'Naam',
        school: 'School',
        tenantID: 'Tenant ID',
        groupTag: 'Groepslabel',
        devices: 'Apparaten',
        externalId: 'Externe ID',
        nameShort: 'Naam kort',
        parentExternalId: 'Ouder extern',
        entityId: 'Entiteit ID',
        defaultGroupTag: 'Standaard Student GroupTag',
        defaultStaffGroupTag: 'Standaard Personeelsgroeptag',
        groupTagLoaner: 'Group Tag Leensysteem',
        contactSupport: 'Neem contact op met Sales Support om deze optie in te schakelen',
        contact: 'Contact',
        updateGroupTag: 'Groepstags bijwerken',
        removeOrganisationFromTenant: 'Organisatie verwijderen uit Tenant',
        doYouWantToRemoveOrganisationFromTenant: 'Wilt u de organisatie verwijderen uit de Tenant',
        confirm: 'Bevestigen',
        cancel: 'Annuleren',
        organisationInformation: 'Organisatie informatie',
        preprovisioningToken: 'Preprovisioning Token',
        organisation: 'Organisatie',
        updateToken: 'Update Provisioning Token',
        enrollTrigger: 'Enroll Trekker',
        repairFlow: 'Herstelstroom',
        setDeviceName: 'Apparaatnaam instellen',
        deviceNameConfig: 'Configuratie van schoolapparaatnaam',
        sequenceNumber: {
          required: 'Apparaatvolgnummer is verplicht.',
          integer: 'Apparaatvolgnummer moet een geheel getal zijn.',
          min: 'Apparaatvolgnummer moet minimaal 0 zijn.',
          max: 'Apparaatvolgnummer mag niet groter zijn dan 5.',
          name: 'Apparaatvolgnummer',
          type: 'Moet een getal zijn tussen 0 en 5.'
        },
        prefixName: {
          name: 'Naam apparaatvoorvoegsel',
          required: 'Apparaatvoorvoegselnaam is verplicht.',
          max: 'Apparaatvoorvoegselnaam mag niet meer dan 15 tekens bevatten.',
          matches:
            'Apparaatvoorvoegselnaam moet alfanumeriek zijn en mag een optionele achtervoegende streepje bevatten.'
        },
        suffixSequence: {
          required: 'De achtervoegselreeks is verplicht.',
          numeric: 'De achtervoegselreeks moet numeriek zijn.',
          padding: 'De achtervoegselreeks moet overeenkomen met de vereiste opvullengte.',
          greaterOrEqual:
            'De achtervoegselreeks moet groter dan of gelijk zijn aan de huidige reeks.',
          combinedMax:
            'De gecombineerde naam (voorvoegsel en achtervoegsel) mag niet langer zijn dan 15 tekens.'
        },
        deviceName: {
          prefix: 'De apparaatnaam',
          tooLong: 'is langer dan 15 tekens',
          currentName: 'Huidige achtervoegselsequentie'
        },
        caution: {
          title: 'Let op: Het wijzigen van de volgorde',
          body: 'Het verhogen van het volgnummer is onomkeerbaar en kan de uniekheid van apparaatsnamen beïnvloeden. Zorg ervoor dat het nieuwe volgnummer geen dubbele namen voor bestaande apparaten veroorzaakt.',
          tip: {
            text: 'Tip:',
            body: 'Controleer de huidige apparaatsnamen voordat u wijzigingen aanbrengt. Raadpleeg uw systeembeheerder als u twijfelt.'
          }
        },
        example: 'Voorbeelden',
        save: 'Opslaan',
        prefixNameGuide:
          'Wanneer de feature op organisatieniveau is ingesteld, moet deze altijd een prefix hebben. Geef de juiste prefixnaam en volgnummers op'
      },
      tenant: {
        name: 'Naam',
        defaultDomainName: 'Standaard domeinnaam',
        initialDomainName: 'Oorspronkelijke domeinnaam',
        tenantId: 'Tenant/domein-ID',
        active: 'Actief',
        updatedAt: 'Bijgewerkt op',
        hostedDomain: 'Gehost domein',
        checkStatus: 'Controleer de status',
        removeTenant: 'Tenant verwijderen',
        actions: 'Acties',
        confirmRemoveTenant: 'Wilt u deze tenant verwijderen?',
        includeUser: 'Gebruiker opnemen',
        no: 'Nee',
        yes: 'Ja',
        cancel: 'Annuleren',
        confirm: 'Bevestigen',
        add: 'Voeg Google-domein toe',
        localAdmin: 'Tenant Beheerder Email',
        domain: 'Gehoste Domein',
        token: 'ZTE Token',
        lang: 'Taal',
        admin: 'Beheerder Email',
        save: 'Opslaan',
        update: 'Bijwerken',
        customerId: 'Klanten ID',
        updateCustomerId: 'Update klant-ID',
        howToObtainCustomerId: 'Hoe klant-ID te verkrijgen?',
        addSchoolToTenant: 'Voeg school toe aan tenant',
        inOtherTenant: 'bij andere tenants',
        filterSchool: 'Filter alleen scholen die tot dezelfde (familie) boom behoren.',
        beforeDelete: 'Druk op "Bevestigen" om het token van deze school/domein te verwijderen',
        filterByEntity: 'Filteren op bedrijfsentiteit',
        enableSetDeviceName:
          'Schakel het beleid voor het instellen van de apparaatnaam in voor deze tenant'
      },
      healthCheck: {
        lastConnection: 'Laatste verbinding',
        applicationConnection: 'Toepassing verbinding',
        permissions: 'Rechten',
        cname: 'Stuurautomaat CNAME',
        customerId: 'Klanten ID',
        zteToken: 'ZTE Token',
        customerIdFail: 'Deze Tenant heeft geen klant-ID',
        zteTokenFail: 'Deze Tenant heeft geen ZTE Token'
      },
      adminOverview: {
        registration: {
          id: 'Id',
          detail: 'Registratie detail',
          name: 'Naam',
          email: 'E-mail',
          tenantDomain: 'Tenant Domein',
          organisationName: 'Schoolnaam',
          mobileNumber: 'Mobiel Nummer',
          status: 'Status',
          createdAt: 'Gemaakt Op',
          actions: 'Acties',
          rejectUser: 'Gebruiker weigeren',
          rejectConfirm: 'Weiger deze registratie met E-mail',
          invite: 'Nodig uit',
          reject: 'Weigeren',
          cancel: 'Annuleren',
          type: 'Type'
        },
        inviteAzureTenant: {
          tenantDomain: 'Tenant domein',
          schoolId: 'School-id',
          tenantAdminEmail: 'Het e-mailadres van de beheerder van de tenant',
          language: 'Taal',
          name: 'Gebruikersnaam',
          applyToChild: 'Toepassen op kind',
          userSchool: 'Gebruiker school'
        },
        inviteGoogleDomain: {
          hostedDomain: 'Gehost domein',
          domainAdminEmail: 'Get beheerdersmailadres van Google',
          language: 'Taal',
          schoolId: 'School-id',
          name: 'Gebruikersnaam',
          applyToChild: 'Toepassen op kind',
          userSchool: 'Gebruiker school'
        },
        users: {
          id: 'Id',
          name: 'Naam',
          email: 'E-mail',
          role: 'Rol',
          status: 'Toestand',
          createdAt: 'Gemaakt bij',
          registrationLink: 'Registratielink',
          add: 'Voeg gebruiker toe',
          selectTenant: 'Selecteer tenant',
          cancel: 'Annuleren',
          save: 'Opslaan',
          language: 'Taal',
          lastSignedIn: 'Laatst aangemeld',
          ipAddress: 'IP-adres',
          detail: {
            id: 'Id',
            name: 'Naam',
            email: 'E-mail',
            role: 'Rol',
            status: 'Toestand',
            createdAt: 'Gemaakt bij',
            approve: 'Goedkeuren',
            reject: 'Afwijzen',
            pending: 'In behandeling',
            active: 'Actief',
            rejected: 'Afgewezen',
            invite: 'Uitnodigen',
            invited: 'Uitgenodigd',
            disabled: 'Gehandicapt',
            accepted: 'Geaccepteerd',
            organization: 'Organisatie',
            save: 'Redden',
            userData: 'Gebruikersgegevens',
            phone: 'Telefoon',
            tenant: 'Azure-tenants',
            googleDomain: 'Google Domeinen',
            defaultDomainName: 'Standaard domeinnaam',
            initialDomainName: 'Initiële domeinnaam',
            tenantId: 'Tenant/domein-ID',
            updatedAt: 'Bijgewerkt op',
            hostedDomain: 'Gehost domein',
            userInfo: 'Gebruikers informatie',
            gender: 'Geslacht',
            emailVerifyAt: 'E-mail verifiëren op',
            organisation_name: 'Naam van de school'
          }
        },
        applyToChild: 'Voeg kindorganisaties toe aan de bovenstaande tenant.',
        company: 'Het bedrijf waar de tenant toe behoort.',
        companyName: 'Bedrijfsnaam'
      },
      overview: {
        company: {
          title: 'Bedrijfsoverzicht',
          add: 'Voeg bedrijf toe',
          search: 'Zoeken op bedrijfsnaam',
          name: 'Naam',
          edit: 'Bewerken'
        },
        role: {
          name: 'Naam',
          guard_name: 'Naam bewaker',
          permissions: 'Rechten',
          save: 'Opslaan',
          create: 'Creëren',
          edit: 'Bewerken',
          createdAt: `Created at`,
          updatedAt: `Updated at`,
          connectedUsers: `Connected users`
        },
        roleDetail: {
          name: `Naam`,
          guard_name: `Naam bewaker`,
          permissions: `Machtigingen`,
          save: `Redden`,
          data_not_found: `Gegevens niet gevonden`,
          connectedUsers: `Verbonden gebruikers`,
          id: `ID`,
          user: `Gebruiker`,
          status: `Status`,
          search: `Zoeken`,
          fullname: `Volledige naam`,
          email: `E-mail`,
          team: `Team`,
          workbench: `Werkbank`,
          enabled: 'Ingeschakeld',
          disabled: `Gehandicapt`,
          state: `State`,
          pageName: `Rol-ID`
        },
        settings: {
          title: 'Instellingen',
          registrations: 'Registraties',
          enableStep4: 'Stap 4 inschakelen Maak een afspraak',
          appointmentLink: 'Afspraak link',
          save: 'Opslaan'
        },
        activities: {
          name: 'Naam',
          account: 'Accounttype',
          method: 'Methode',
          description: 'Beschrijving',
          createdAt: 'Bijgehouden op',
          userId: 'Gebruikers-ID',
          action: 'Actie',
          activityLog: 'Activiteitenlogboek',
          id: 'ID',
          pageName: 'Activiteitenlogboeken',
          user: 'Gebruiker',
          key: 'Sleutel',
          original: 'Originele gegevens',
          new: 'Nieuwe gegevens',
          data: 'Gegevens',
          model: 'Model',
          dataChanged: 'Gegevens gewijzigd',
          userType: 'Gebruikerstype',
          searchByDes: 'Zoeken op beschrijvin'
        }
      },
      userRegister: {
        title: 'Welkom bij Easy4enroll',
        greeting: 'Beste Systeembeheerder,',
        useTheGoogleButton:
          "Maak gebruik van de 'Login'-knop hieronder om u te registreren voor Google Zero-touch Enrollment (ZTE) en uw ZTE-token in te voeren voor Easy4enroll. ",
        googleBodyContent1:
          'Geweldig dat je je wilt registreren voor easy4Enroll en Google ZTE (Zero-Touch Enrollment).',
        googleBodyContent2:
          'Om de easy4Enroll in te kunnen richten, hebben we een paar vragen over de contact gegevens van jullie google administrator, het Google-domein en het ZTE token.',
        googleBodyContent3:
          'Zodra de vragen zijn beantwoord zullen wij het systeem inrichten om nieuwe Chromebooks automatisch te registreren.',
        googleBodyContent4:
          "Bij vragen of als je meerdere OU's hebt, neem even contact op te nemen",
        bodyContent1:
          'Bedankt voor uw interesse in het aansluiten bij Easy4enroll. Dit eenvoudige principe heeft een groot potentieel om zowel tijd als frustratie te besparen voor ons allemaal. Easy4enroll maakt een naadloze verbinding met uw Intune-omgeving, waardoor wij (The Rent Company) in staat zijn om automatisch systemen die onder ons beheer vallen te registreren en te verwijderen in uw Intune-omgeving.',
        bodyContent2:
          'Om te kunnen registreren voor Easy4enroll, hebben we een paar vragen over uw Intune-omgeving. Zodra deze vragen zijn beantwoord, zullen we het registratieproces in gang zetten.',
        bodyContent3: 'Als u nog vragen heeft, neem dan gerust contact met ons op via ',
        note: 'OPMERKING: gebruik alstublieft een e-mail waarmee u kunt inloggen op Microsoft Azure',
        googleNote:
          'OPMERKING: gebruik alstublieft een e-mail waarmee u kunt inloggen op Google Admin',
        zte: 'ZTE Token',
        pleaseCheck: 'OPMERKING: controleer deze ',
        forGetToken: ' om de pre-provisioning token te krijgen',
        forGetCid: ' om de Customer id te krijgen',
        zteError: 'ZTE Token is een verplicht veld.',
        customerError: 'Customer Id is een verplicht veld.',
        or: 'of',
        fullname: 'Volledige naam',
        email: 'Email',
        schoolName: 'Naam van de school',
        tenantDomain: 'Domeinnaam van de tenant',
        submit: 'Verzenden',
        mobileNumber: 'Mobiel nummer',
        language: 'Taal',
        fullnameError: 'Volledige naam is een verplicht veld.',
        emailError: 'E-mail is een verplicht veld.',
        emailNotValid: 'E-mail is geen geldig formaat.',
        schoolNameError: 'Schoolnaam is een verplicht veld.',
        tenantDomainError: 'Tenant domein is een verplicht veld.',
        mobileNumberError: 'Mobiel nummer is een verplicht veld.',
        mobileNumberInvalid: 'Mobiel nummer is geen geldig formaat',
        thankYouPage: {
          title: 'Dank je',
          thankYouForRegister: 'Bedankt voor het registreren van uw tenant domein bij easy4Enroll',
          yourSubmission: 'Uw inzending wordt beoordeeld door onze beheerders.',
          whenApproved:
            'Wanneer uw inzending is goedgekeurd, ontvangt u een e-mail met verdere instructies.'
        }
      }
    },
    validation: {
      emailRequired: 'E-mail is een verplicht veld.',
      emailInvalid: 'E-mail is een ongeldig formaat.',
      schoolAndEntityReuiredTogether:
        'Zowel school als Entity Tid zijn samen vereist of moeten nul zijn.',
      ggEmailRequired: 'Google domeinbeheerder e-mail is vereist.',
      domainRequired: 'Domein is vereist',
      nameRequired: 'Naam is vereist',
      tenantDomain: 'Tenant Domein is vereist',
      orgRequired: 'Organisatie is verplicht',
      requiredOneSerialOrPKID: 'Minimaal een serienummer of pkid, of beide'
    }
  }
}
