import { Info } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import { LoadingButton } from '@mui/lab'
import { Box, Link, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { useMemo, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import CompanySchoolFormSelector from '~/components/CompanySchoolFormSelector'
import CustomModal from '~/components/Modal/CustomModal'
import { useGetOrgsQuey } from '~/pages/Admin/Administrator/InviteAzureTenants/query'
import { useAddGoogleTenantMutation } from './mutation'
import ListToolbar from '~/components/ListToolbar'

const schema = yup.object({
  domain: yup.string().required(),
  token: yup.string().required(),
  local_admin: yup.string().email().required(),
  customer_id: yup.string().required(),
  school_id: yup
    .string()
    .nullable()
    .test({
      name: 'schoolEntityCheck',
      message: 'Both school_id and entity_id are required together or should be null',
      test: function (value) {
        const { entity_id } = this.parent
        if ((value && !entity_id) || (!value && entity_id)) {
          return false
        }
        return true
      }
    }),
  entity_id: yup
    .string()
    .nullable()
    .test({
      name: 'entitySchoolCheck',
      message: 'Both entity_id and school_id are required together or should be null',
      test: function (value) {
        const { school_id } = this.parent
        if ((value && !school_id) || (!value && school_id)) {
          return false
        }
        return true
      }
    })
})

const localization = 'pages.tenant.'
const AddGoogleTenantModal = () => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const { data: orgData } = useGetOrgsQuey()
  const { mutate, isLoading: isAddingGgTenant } = useAddGoogleTenantMutation()

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      domain: '',
      token: '',
      local_admin: '',
      school_id: '',
      entity_id: '',
      customer_id: ''
    }
  })

  const handleCloseModal = () => {
    setOpen(false)
    reset()
  }

  const onSubmit = values => {
    mutate(values, {
      onSuccess: handleCloseModal
    })
  }

  const toolBarButtons = useMemo(() => {
    return [
      {
        button: 'addGoogleDomain',
        icon: <AddIcon />,
        onClick: e => {
          setOpen(true)
        }
      }
    ]
  }, [])

  return (
    <>
      <ListToolbar toolBarButton={toolBarButtons} />
      <CustomModal
        open={open}
        onClose={handleCloseModal}
        style={{
          width: 800
        }}
      >
        <Stack gap={2}>
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: 600
            }}
          >
            {t(`${localization}add`)}
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography> {t(`${localization}localAdmin`)}</Typography>
            <TextField
              fullWidth
              sx={{ mb: 2 }}
              variant='outlined'
              {...register('local_admin')}
              error={!!errors.local_admin}
              helperText={<>{errors.local_admin?.message}</>}
            />

            <Typography>{t(`${localization}domain`)}</Typography>
            <TextField
              fullWidth
              sx={{ mb: 2 }}
              variant='outlined'
              {...register('domain')}
              error={!!errors.domain}
              helperText={<>{errors.domain?.message}</>}
            />

            <Typography>{t(`${localization}token`)}</Typography>
            <TextField
              fullWidth
              sx={{ mb: 2 }}
              variant='outlined'
              {...register('token')}
              error={!!errors.token}
              helperText={<>{errors.token?.message}</>}
            />

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1
              }}
            >
              <Typography>{t(`${localization}customerId`)}</Typography>
              <Tooltip
                title={
                  <Link
                    sx={{
                      color: 'white'
                    }}
                    target='_blank'
                    href='https://support.google.com/a/answer/10070793?hl=en'
                  >
                    {t(`${localization}howToObtainCustomerId`)}
                  </Link>
                }
                placement='right'
              >
                <Info style={{ color: 'blue' }} />
              </Tooltip>
            </Box>
            <TextField
              fullWidth
              sx={{ mb: 2 }}
              variant='outlined'
              {...register('customer_id')}
              error={!!errors.customer_id}
              helperText={<>{errors.customer_id?.message}</>}
            />

            <CompanySchoolFormSelector
              errors={errors}
              control={control}
              orgData={orgData}
              setValue={setValue}
            />

            <Stack sx={{ mt: 2 }} direction='row-reverse' gap={1}>
              <LoadingButton
                variant='contained'
                type='submit'
                loading={isAddingGgTenant}
                onClick={handleSubmit}
              >
                {t(`${localization}save`)}
              </LoadingButton>
              <LoadingButton variant='contained' onClick={handleCloseModal}>
                {t(`${localization}cancel`)}
              </LoadingButton>
            </Stack>
          </form>
        </Stack>
      </CustomModal>
    </>
  )
}

export default AddGoogleTenantModal
