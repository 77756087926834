import { Breadcrumbs, Divider, Paper, Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import AzureDeviceTable from './Azure/DeviceOverview/AzureDeviceTable'

import { useTranslation } from 'react-i18next'
import LoopIcon from '~/components/Button/LoopIcon'
import ToggleButton from '~/components/Button/ToggleButton'
import PaginationTable from '~/components/PaginationTable'
import TableFilters from '~/components/Table/TableFilters'
import { AZURE, GOOGLE } from '~/constants/constants'
import useTable from '~/hooks/useTable'
import GoogleDeviceTable from './GoogleDeviceOverview/GoogleDeviceTable'
import { useAzureDeviceListOverviewQuery, useGoogleDeviceListOverviewQuery } from './query'

const DeviceOverview = () => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState('pending')
  const [deviceType, setDeviceType] = useState(AZURE)
  const [fetching, setFetching] = useState(
    localStorage.getItem('ADMIN_IS_FETCHING_DEVICE') === 'true'
  )
  const [refetchInterval, setRefectchInterval] = useState(0)

  const query = useMemo(() => {
    if (deviceType === AZURE) {
      return useAzureDeviceListOverviewQuery
    }
    return useGoogleDeviceListOverviewQuery
  }, [deviceType])

  const {
    data,
    search,
    isLoading,
    order,
    page,
    orderBy,
    searchHandler,
    refetch,
    sortHandler,
    changePageHandler,
    onEnterSearch
  } = useTable({
    query,
    initialStates: {
      order: 'desc',
      page: 1,
      orderBy: 'created_at'
    },
    queryAttributes: {
      filter: selected
    },
    additionalAttributes: {
      refetchInterval
    }
  })

  const devices = useMemo(() => {
    return data ? data.data : []
  }, [data])

  useEffect(() => {
    if (fetching) {
      refetch()
      setRefectchInterval(5000)
    } else {
      setRefectchInterval(0)
    }
  }, [fetching, refetch])

  const selectGroup = useMemo(() => {
    const listOptions = [
      { name: 'Pending', id: 'pending' },
      { name: 'Last 5 minutes', id: 'last5Minutes' },
      { name: 'Last 30 minutes', id: 'last30Minutes' },
      { name: 'Last hour', id: 'lastHour' },
      { name: 'Today', id: 'today' },
      { name: 'Last week', id: 'lastWeek' }
    ]
    return [
      {
        label: 'Type',
        setValue: setDeviceType,
        dataSet: [
          { name: AZURE, id: AZURE },
          { name: GOOGLE, id: GOOGLE }
        ],
        value: deviceType,
        defaultValue: AZURE
      },
      {
        label: 'Filter',
        setValue: setSelected,
        dataSet: listOptions,
        value: selected,
        defaultValue: 'pending'
      }
    ]
  }, [deviceType, selected])

  const DeviceTableComponents = {
    azure: AzureDeviceTable,
    google: GoogleDeviceTable
  }

  const SelectedDeviceTable = DeviceTableComponents[deviceType]

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb'>
        <Typography>Device Overview</Typography>
      </Breadcrumbs>
      <Divider />
      <Paper elevation={1}>
        <TableFilters
          {...{
            selectGroup,
            search,
            searchHandler
          }}
          onEnterSearch={onEnterSearch}
          searchPlaceHolder={t('general.searchFor') + t('general.serials_or_importStatus')}
          page='devices-overview'
        >
          <ToggleButton
            content={<LoopIcon isFetching={fetching} />}
            value='check'
            selected={fetching}
            onChange={e => {
              localStorage.setItem('ADMIN_IS_FETCHING_DEVICE', !fetching)
              setFetching(v => !v)
            }}
          />
        </TableFilters>
        <Divider />
        {SelectedDeviceTable && (
          <>
            <SelectedDeviceTable
              order={order}
              orderBy={orderBy}
              isLoading={isLoading}
              devices={devices}
              handleRequestSort={sortHandler}
            />
            <PaginationTable
              data={data}
              page={page}
              size='medium'
              handleChangePage={changePageHandler}
            />
          </>
        )}
      </Paper>
    </>
  )
}

export default DeviceOverview
