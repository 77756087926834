import AddIcon from '@mui/icons-material/Add'
import {
  Divider,
  Link as MuiLink,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import BreadCrumb from '~/components/BreadCrumb'
import DataNotFound from '~/components/DataNotFound'
import DateTimeLocale from '~/components/DateTimeLocale'
import ListToolbar from '~/components/ListToolbar'
import PaginationTable from '~/components/PaginationTable'
import TableFilters from '~/components/Table/TableFilters'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import {
  ADMIN_USER_COMPANY,
  ADMIN_USER_COMPANY_DETAIL,
  ADMIN_USER_COMPANY_NEW
} from '~/constants/Routes'
import { useAdminPermission } from '~/hooks/useAdminPermission'
import useTable from '~/hooks/useTable'
import { CompanyHeadCells } from './CompanyHeadCells'
import { useOverviewCompaniesQuery } from './query'

const CompanyTableBody = ({ isLoading, companies }) => {
  if (isLoading) {
    return <TableLoading colSpan={CompanyHeadCells.length} />
  }

  return (
    <TableBody>
      {companies.length === 0 && <DataNotFound colSpan={CompanyHeadCells.length} />}
      {companies.length > 0 &&
        companies.map((row, index) => {
          return (
            <TableRow hover key={index}>
              <TableCell align='left'>
                <Link to={ADMIN_USER_COMPANY_DETAIL.replace(':id', row.id)}>
                  <MuiLink>{row.id}</MuiLink>
                </Link>
              </TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>
                <DateTimeLocale datetime={row.created_at} />
              </TableCell>
              <TableCell>
                <DateTimeLocale datetime={row.updated_at} />
              </TableCell>
            </TableRow>
          )
        })}
    </TableBody>
  )
}

CompanyTableBody.propTypes = {
  isLoading: PropTypes.bool,
  companies: PropTypes.array
}

CompanyTableBody.defaultProps = {
  isLoading: false,
  companies: []
}

const CompanyOverview = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { overviewUser } = useAdminPermission()

  const {
    data: companyData,
    search,
    isLoading,
    order,
    page,
    orderBy,
    searchHandler,
    onEnterSearch,
    sortHandler,
    setPage
  } = useTable({
    query: useOverviewCompaniesQuery,
    initialStates: {
      order: 'asc',
      page: 1,
      orderBy: 'name'
    }
  })

  const handleChangePage = useCallback(
    (_, value) => {
      setPage(value)
    },
    [setPage]
  )

  const BreadComponents = [
    {
      title: 'Administrator'
    },
    {
      route: ADMIN_USER_COMPANY,
      title: 'Company Overview'
    }
  ]

  const toolBarButtons = useMemo(() => {
    return [
      {
        button: 'addCompany',
        icon: <AddIcon />,
        disabled: !overviewUser.administrator.companies.canCreate,
        onClick: e => {
          navigate(ADMIN_USER_COMPANY_NEW)
        }
      }
    ]
  }, [navigate, overviewUser.administrator.companies.canCreate])

  return (
    <>
      <BreadCrumb breadcrumbs={BreadComponents} />
      <Divider />
      <Paper>
        <ListToolbar toolBarButton={toolBarButtons} />
        <TableFilters
          {...{
            search,
            searchHandler
          }}
          onEnterSearch={onEnterSearch}
          searchPlaceHolder={t('general.searchFor') + t('general.serials_or_importStatus')}
          page='companies-overview'
        />
        <Divider />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size='medium'>
            <TableHeader
              headCells={CompanyHeadCells}
              onRequestSort={sortHandler}
              order={order}
              orderBy={orderBy}
              localization=''
            />
            <CompanyTableBody isLoading={isLoading} companies={companyData?.data || []} />
          </Table>
        </TableContainer>
        <PaginationTable
          data={companyData}
          page={page}
          size='medium'
          handleChangePage={handleChangePage}
        />
      </Paper>
    </>
  )
}

export default CompanyOverview
